import React, {useContext, useRef} from 'react';
import PropTypes from 'prop-types';
import {AnimatePresence, motion, useAnimation, useInView, useIsPresent} from 'framer-motion';
import {Parallax} from "react-scroll-parallax";
import {Link} from "react-router-dom";
import {NavContext, NavContextType} from "../../util/nav_context";
import {PlusIcon} from "@heroicons/react/24/solid";
import Slider from "../../components/slider/slider";
import moment from "moment";


About.propTypes = {

};

const partners = [
    {id: 1, name: 'NNLG', img: '/img/about/nnlg.png', detail: 'NLNG was incorporated to harness Nigeria’s vast natural gas resources and produce Liquefied Natural Gas (LNG) and Natural Gas Liquids (NGLs) for export.'},
    {id: 2, name: 'CBN', img: '/img/about/cbn.png', detail: 'The CBN of the Federal Republic of Nigeria charges the Bank with the overall control and administration of the monetary and financial sector policies of the Federal Government.'},
    {id: 3, name: 'Total', img: '/img/about/total.png', detail: 'TotalEnergies is a multi-energy company that produces and markets fuels, natural gas and electricity.'},
    {id: 4, name: 'Pro Serve Service ltd', img: '/img/about/pro-serve.png', detail: 'A professional global outlook and world-class expertise focused on bringing client’s ideas to reality through delivering comprehensive project Management services in real estate,'},
    {id: 4, name: 'Courtney Michael', img: '/img/about/Courtney-Michael-Logo.png', detail: 'Courtney Michael Partnership Nigeria Limited is the foremost Construction Project Management Company in Nigeria managing projects of over US$5 billion for blue chip clients in Nigeria and West Africa sub region.'},
];

const core = [
    {id: 1, name: 'Employees',  detail: 'Attract & retain the best hands by offering opportunities & quality Life.'},
    {id: 2, name: 'Safety',  detail: ' Superior Outcomes through Proactive Engineering & Management.'},
    {id: 3, name: 'Communication',  detail: 'Open, Fair & Honest'},
    {id: 4, name: 'Relationships',  detail: 'Mutually beneficial and anchored on a passion of excellence and on a  long term basis.'},
    {id: 5, name: 'Attitude',  detail: ' Confident, Positive & Successful'},
    {id: 6, name: 'Ethics',  detail: 'Working in line with international best practices and operational standards.'},
    {id: 7, name: 'Quality',  detail: 'Continuous improvement & done right the first time'},
    {id: 8, name: 'Price',  detail: 'Best value/ efficient / competitive'},
    {id: 9, name: 'Schedules',  detail: 'Completion on / ahead of schedule'},
];



function About(props:any) {
    const isPresent = useIsPresent();

    function truncateText(text: string, maxLength: number) {
        if (text.length <= maxLength) {
            return text;
        } else {
            // Truncate the text and add ellipses
            return text.slice(0, maxLength) + '...';
        }
    }
    const targetRef = useRef<HTMLDivElement | null>(null);
    const ref = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const isInView = useInView(ref, { once: true });
    const isInView2 = useInView(ref2, { once: true });
    const isInView3 = useInView(ref3, { once: true });
    const isInView4 = useInView(ref4, { once: true });
    const isInView5 = useInView(ref5, { once: true });
    const isInView6 = useInView(ref6, { once: true });

    return (
        <div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 1.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 1.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color absolute top-0 left-0 z-50"
            ></motion.div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 1, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: ``, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color2 absolute top-0 left-0 z-50"
            ></motion.div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 0.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color3 absolute top-0 left-0 z-50"
            ></motion.div>
            <section className={`relative`}>
                <div className={`menu-color h-screen-65`}>
                    <div className={`mx-auto h-screen-65 flex justify-center items-center`}>
                        <div className={`flex flex-col-reverse md:flex-row  mx-auto justify-center items-center h-full `}>
                            <div className={`md:w-5/12 px-3 pb-14 md:px-10`}>
                                <p className={`md:text-4xl font-bold mt-10 my pb-4 md:pb-14 tracking-normal text-white`}>
                                    About us
                                </p>
                                <p className={`md:text-2xl pb-4 tracking-normal text-white`}>

                                    Rainbow Heritage Group is a strong, versatile, highly productive medium sized company, unburdened by the large overhead costs carried by major consultancy companies.
                                </p>
                                <Link to="/about">
                                    <button className={`main-color text-black text-md font-semibold px-10 md:px-20 py-2 rounded`}>
                                        About us
                                    </button>
                                </Link>
                            </div>
                            <div className={`md:w-7/12 h-full`} style={{ backgroundImage: `url('/img/about/part1.png')`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                                <img src="/img/about/part1.png" alt="" className={`md:hidden`}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={`min-h-screen-75 relative`}>
                <div className={`absolute`}>
                    <img src="/img/home/sec2_bg_img_1.png" alt="" className={`w-32`}/>
                </div>
                <div className={`absolute right-0 top-72`}>
                    <img src="/img/home/sec2_bg_img_2.png" alt="" className={`w-56`}/>
                </div>
                <div className={`container mx-auto min-h-screen-75 py-20 px-2 md:px-0`}>
                    <div className={`flex flex-col justify-center h-full mt-10`}>

                        <Parallax speed={11}>
                            <div
                                ref={ref}
                                style={{
                                    transform: isInView ? "none" : "translateY(100px)",
                                    opacity: isInView ? 1 : 0,
                                    transition: "all 3s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                                }}
                                className={`flex flex-col container mx-auto md:w-10/12`}>
                                <div className="flex flex-col md:flex-row center">
                                    <div className="flex md:w-6/12">
                                        <img src="/img/about/part2-1.png" alt=""/>
                                    </div>
                                    <div className="flex flex-col py-10 justify-center md:w-6/12 px-2 md:px-20">
                                        <p className={`md:text-2xl font-bold tracking-normal`}>
                                            Our Mission
                                        </p>
                                        <p className={` tracking-normal `}>
                                             Our mission is to exceed the expectations of all our stakeholders by delivering exceptional projects that not only enhance the built environment but also contribute positively to the social and economic development of the communities we serve.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col-reverse md:flex-row center">
                                    <div className="flex flex-col py-10 justify-center md:w-6/12 px-2 md:px-20">
                                        <p className={`md:text-2xl font-bold tracking-normal`}>
                                            Our Vision
                                        </p>
                                        <p className={` tracking-normal `}>
                                            To be the preferred urban real estate development company, offering high quality and customised solutions across sub-Saharan Africa.
                                        </p>
                                    </div>
                                    <div className="flex md:w-6/12">
                                        <img src="/img/about/part2-2.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </Parallax>
                    </div>

                </div>
            </section>
            <section className={`lg:h-screen-75  overflow-hidden menu-color`}>
                <div className={`flex justify-center container mx-auto items-center h-full relative`}>
                    <div className={`absolute`}>
                        <img src="/img/home/testimonial_img.png" alt="" className={`h-120`}/>
                    </div>
                    <div className={`w-full grid md:grid-cols-2 text-xl items-center`}>
                        <div className="md:w-6/6">
                            <Parallax speed={8} className={`mt-10 md:mt-0 md:w-6/6`}>
                                <div className={`mx-auto container justify-start py-4 px-3`}>
                                    <p className={`text-3xl md:text-4xl main-text-color font-semibold cormorant-font`}>
                                        Our Business Policies
                                    </p>
                                    <p className={`text-white md:pr-36 tracking-normal text-lg`}>
                                        We believe in doing ‘good business’ in line with existing national and international standards, laws and bye-laws based on:
                                        Ethical ConductsEmployee RelationshipsQuality performanceHSE performance complianceControl and finance prudence
                                    </p>
                                </div>
                            </Parallax>
                        </div>
                        <div className="md:w-6/6">
                            <Parallax speed={8} className={`md:w-6/6`}>
                                <div className={`mx-auto container text-center py-4 text-start`}>
                                    <p className={`text-3xl md:text-4xl main-text-color font-semibold cormorant-font`}>
                                        Our Core Values
                                    </p>

                                    <div className={`flex-col text-white `}>
                                        {core.map((cor:any, index:any) => (
                                            <p className={`py-3 tracking-normal text-lg`}><span className={`font-semibold`}>{cor.name}: </span>{cor.detail}</p>
                                        ))}
                                    </div>
                                </div>
                            </Parallax>
                        </div>

                    </div>
                </div>
            </section>

            <section className={`relative`}>
                <div className={`absolute right-0 top-20`}>
                    <img src="/img/home/sec2_bg_img_2.png" alt="" className={`w-56`}/>
                </div>
                <div className={`container mx-auto py-20 px-2 md:px-0`}>
                    <div className={`flex flex-col justify-center h-full mt-10`}>
                        <div className={`flex justify-center items-center h-full relative`}>
                            <div
                                ref={ref3}
                                style={{
                                    transform: isInView3 ? "none" : "translateY(100px)",
                                    opacity: isInView3 ? 1 : 0,
                                    transition: "all 3s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                                }}
                                className={`flex flex-col`}>
                                <Parallax speed={2}>
                                    <div className={`mx-auto text-lg container text-center py-4 md:w-5/12`}>
                                        <p>

                                        </p>
                                        <p className={`text-xl  font-semibold cormorant-font`}>

                                        </p>
                                    </div>
                                </Parallax>
                            </div>
                        </div>
                        <Parallax speed={11}>
                            <div
                                ref={ref3}
                                style={{
                                    transform: isInView3 ? "none" : "translateY(100px)",
                                    opacity: isInView3 ? 1 : 0,
                                    transition: "all 3s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                                }}
                                className={` container mx-auto `}>
                                <div className="flex flex-col md:flex-row justify-center items-center">
                                    <div className="flex flex-col justify-center lg:w-5/12 px-2">
                                        <p className={`text-3xl md:text-4xl px-2 lg:px-20 font-bold tracking-normal pb-10 cormorant-font`}>
                                            Our Corporate Brief
                                        </p>
                                        <p className={` tracking-normal pb-6 px-2 md:pl-14 font-semibold`}>
                                            Rainbow Heritage Group (RHG) is an indigenous company duly incorporated with the Corporate Affairs Commission in Nigeria with RC Number 826133. Rainbow Heritage Group is actively engaged in Real Estate development (Residential and Commercial), Infrastructural Development, Integrated Construction Solutions, Facility Management, and Project Management.
                                            Rainbow Heritage Group was incorporated on June 22, 2009 as a private limited liability company under the provisions of the Companies and Allied Matters Act, 1990. The paid-up share capital is ₦10,000,000 (Ten Million Naira Only) divided into ten million ordinary shares of ₦1 each.
                                        </p>
                                        <p className={` tracking-normal pb-6 px-2 `}>
                                            Our professionals have vast working experience in the Oil and Gas, Real Estate, industrial and civil engineering construction sectors with proven capacity and reputation to deliver optimally to commission and start up.
                                            Rainbow Heritage Group brings to the field a range of sector-specific solutions with a service model that is based on an uncompromising standard for efficiency and adaptability to project needs.
                                        </p>
                                        <p className={` tracking-normal pb-6 px-2 md:pl-14`}>
                                            Our consistent track record is testament not only to our focus and effectiveness in the industry, but also on richly investing in our people. Our backbone remains the team of engineers and experts and their wealth of knowledge and experience in all our business services.
                                            We aspire for excellence in all our performances and expect nothing less in a bid to ensure the success of all our clients’ projects. And so, our investment strategy hinges on the quality of being reliable in all our engagements.
                                        </p>
                                    </div>
                                    <div className="flex lg:w-5/12 relative">
                                        <div className={`absolute top-1/2 -translate-y-1/2`}>
                                            <div className={`w-8/12 flex items-end`}>
                                                <Parallax speed={11}>
                                                <img src="/img/about/part4-1.png" alt=""/>
                                                </Parallax>
                                            </div>
                                        </div>
                                        <div className={`w-3/12 flex self-end`}></div>
                                        <div className={`w-9/12 flex self-end`}>
                                            <img src="/img/about/part4-2.png" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Parallax>
                    </div>
                </div>
            </section>
            <section className={`lg:h-screen-75 overflow-hidden menu-color `}>
                <div className={`flex justify-center container mx-auto items-center h-full relative`}>
                    <div className={`w-full items-center grid md:grid-cols-2`}>
                        <div className="md:w-6/6">
                            <Parallax speed={8} className={`mt-10  lg:mt-0`}>
                                <div
                                    ref={ref4}
                                    style={{
                                        transform: isInView4 ? "none" : "translateY(100px)",
                                        opacity: isInView4 ? 1 : 0,
                                        transition: "all 3s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                                    }}
                                    className={`mx-auto container  justify-center py-4`}>
                                    <div className="grid grid-cols-2">
                                        <div className="flex flex-col items-center col-span-1">
                                            <p className={`text-4xl md:text-6xl main-text-color font-semibold cormorant-font`}>
                                                102
                                            </p>
                                            <p className={`text-white text-xl md:text-2xl`}>
                                                Field Workers
                                            </p>
                                        </div>
                                        <div className="flex flex-col items-center col-span-1">
                                            <p className={`text-4xl md:text-6xl main-text-color font-semibold cormorant-font`}>
                                                3
                                            </p>
                                            <p className={`text-white text-xl md:text-2xl`}>
                                                No of Projects
                                            </p>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-2 mt-20 md:mt-40">
                                        <div className="flex flex-col items-center col-span-1">
                                            <p className={`text-4xl md:text-6xl main-text-color font-semibold cormorant-font`}>
                                                10
                                            </p>
                                            <p className={`text-white text-xl md:text-2xl`}>
                                                Years Established
                                            </p>
                                        </div>
                                        <div className="flex flex-col items-center col-span-1">
                                            <p className={`text-4xl md:text-6xl main-text-color font-semibold cormorant-font`}>
                                                100%
                                            </p>
                                            <p className={`text-white text-xl md:text-2xl`}>
                                                Satisfaction
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </Parallax>
                        </div>
                        <div className="md:w-6/6 relative h-full w-full">
                            <div className="absolute left-0 top-1/2  transform -translate-y-1/2 -translate-x-0">
                                <div className="flex justify-start">
                                    <div className="flex gap-7 items-center transform rotate-90 -ml-96">
                                        <div className="line w-52"></div>
                                        <p className="text-white whitespace-nowrap">Our Human Resource Capital</p>
                                        <div className="line w-52"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-start my-10 md:hidden">
                                <div className="flex gap-7 items-center w-full ">
                                    <div className="line w-full"></div>
                                    <p className="text-white whitespace-nowrap">Our Human Resource Capital</p>
                                    <div className="line w-full"></div>
                                </div>
                            </div>
                            <div className={`mx-auto container px-2 md:px-28 pt-16 pb-32  text-white`}>
                                <p className={``}>
                                    <span className={`font-semibold text-xl`}> Human Resources</span> <br/>
                                    We Excel In Our Services Because We Ensure That All Our Operations And Management Personnel Are Reliable, Qualified And Competent
                                </p><br/>
                                <p className={` `}>
                                    <span className={`font-semibold text-xl`}>Work Capacity and Resources</span><br/>
                                    Integrity of personnel and management, structures, and workmanship, has provided the foundation with which Rainbow Heritage Group has developed itself.
                                </p><br/>
                                <p className={` `}>
                                    <span className={`font-semibold text-xl`}>High Quality Management</span><br/>
                                    The success of RHPS is determined by the establishment of an efficient and effective management team that can successfully apply international best practices and processes.
                                </p>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
            <section className={`container mx-auto px-3 mb-16`}>
                <div className={`flex flex-col md:flex-row w-full items-center justify-between py-10 md:py-20`}>
                    <p className={`font-semibold cormorant-font text-3xl md:text-5xl md:mb-5`}>
                        Rainbow Heritage <span className={`main-text-color`}>Partners</span>
                    </p>
                    <p className={`md:w-5/12`}>
                        We strive to provide you with customise solution to home delivery, but we can’t do it alone. Rainbow Heritage group has developed partnerships with companies that share our desire to please.
                    </p>
                </div>
                <div
                    ref={ref5}
                    style={{
                        transform: isInView5 ? "none" : "translateY(100px)",
                        opacity: isInView5 ? 1 : 0,
                        transition: "all 3s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                    }}
                    className={`grid md:grid-cols-4 gap-7 h-full`}>
                    {partners?.map((partner:any, index:number) => (
                        <div key={index} className={`flex flex-col`}>
                                <div className={`py-20 px-10 h-52 bg-white flex justify-center items-center transform transition cursor-pointer delay-100 duration-1000 ease-in-out hover:scale-105`} >
                                    <img src={partner.img} alt=""/>
                                </div>

                                <p className={`py-2 font-semibold text-xl`}>
                                    {partner.name}
                                </p>
                            <p className={`pb-5`}>
                                {truncateText(partner.detail, 150)}
                            </p>
                        </div>
                    ))}

                </div>
            </section>

        </div>
    );
}

export default About;
