import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import homePageReducer from '../features/home_page/homePageApiSlice';
import designPageReducer from '../features/design/designPageApiSlice';
import projectsPageReducer from '../features/projects/projectsPageApiSlice';
import blogPageReducer from '../features/blog/blogPageApiSlice';
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    homePage: homePageReducer,
    designPage: designPageReducer,
    projectsPage: projectsPageReducer,
    blogsPage: blogPageReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
