import React from 'react';
import {motion, useIsPresent} from "framer-motion";

function Opener(props:any) {
    const isPresent = useIsPresent();
    return (
        <div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 1.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 1.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color absolute top-0 left-0 z-50"
            ></motion.div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 1, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: ``, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color2 absolute top-0 left-0 z-50"
            ></motion.div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 0.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color3 absolute top-0 left-0 z-50"
            ></motion.div>
        </div>
    );
}

export default Opener;