import React, {useContext, useState} from 'react';
import { motion,  useIsPresent} from 'framer-motion';
import {Parallax} from "react-scroll-parallax";
import {NavContext, NavContextType} from "../../util/nav_context";
import Contact from "../../components/contact/contact";
import Testimonial from "../../components/testimonial/testimonial";
import Slider from "../../components/slider/slider";
import CompaniesCarousel from "../../components/companies_carousel/companies_carousel";
import DesignFilter from './design_filter';
import {Link} from "react-router-dom";

Designs.propTypes = {

};
interface Design {
    id: number;
    title: string;
    description: string;
    features: string[];
    overview: string;
    bedrooms: number;
    bathrooms: number;
    main_image: string;
    garage: number;
    square_foot: number;
    slug: string;
}



function Designs(props:any) {
    const isPresent = useIsPresent();
    const navContextData = useContext(NavContext) as NavContextType
    const { DesignsPage } = navContextData
    console.log(DesignsPage)

    const [filteredDesigns, setFilteredDesigns] = useState<Design[]>(DesignsPage);

    const handleFilter = (filteredDesigns: Design[]) => {
        setFilteredDesigns(filteredDesigns);
    };

    function truncateText(text: string, maxLength: number) {
        if (text.length <= maxLength) {
            return text;
        } else {
            // Truncate the text and add ellipses
            return text.slice(0, maxLength) + '...';
        }
    }
    const resources = [
        {
            title: "Serviced Lot",
            description: "Subscribing to this option gives the purchaser full access to a property(lot) that has been prepared with necessary infrastructure and utilities for residential or commercial development. Our company ensures the provision of a robust infrastructure and the subscriber is simply plugged into already existing infrastructure."
        },
        {
            title: "Carcass",
            description: "The property is delivered with only the construction of the building, roofing and finishing of the exterior, allowing the subscriber to finish the interior to his/her taste."
        },
        {
            title: "Turnkey or Finished",
            description: "Subscribers are guaranteed a property that is delivered fully completed to a high standard and in ready to use state. This includes not only the structural elements but also the exterior and interior finishing."
        }
    ]

    return (
        <div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 1.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 1.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color absolute top-0 left-0 z-50"
            ></motion.div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 1, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: ``, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color2 absolute top-0 left-0 z-50"
            ></motion.div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 0.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color3 absolute top-0 left-0 z-50"
            ></motion.div>
            <section className={`relative`}>
                <div className={`menu-color h-screen-65`} >
                    <div className={`mx-auto h-screen-65 flex justify-center items-center`}>
                        <div className={`flex flex-col-reverse md:flex-row  mx-auto justify-center items-center h-full `}>
                            <div className={`mx-auto container text-center py-4 w-full`}>
                                <div className={`grid md:grid-cols-3`}>
                                    <div className={`flex items-center gap-6`}>
                                        <p className={`text-white font-semibold text-2xl`}>
                                            Explore Our Designs
                                        </p>
                                        <img src="/img/designs/group1.png" alt="" className={`h-32 -mt-20 animate-bounce`}/>
                                        <img src="/img/designs/group2.png" alt="" className={`h-32 mt-60 animate-bounce`}/>
                                    </div>
                                    <div className={`m-5 xl:m-16 hidden md:block`}>
                                        <Parallax speed={-3}>
                                            <img src="/img/designs/building1.png" alt="" className={`mt-28`}/>
                                        </Parallax>
                                    </div>
                                    <div className={`m-5 xl:m-16 hidden md:block`}>
                                        <Parallax speed={6}>
                                            <img src="/img/designs/building2.png" alt="" className={`mt-60`}/>
                                        </Parallax>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={`min-h-screen-75 relative mt-10 md:mt-20`}>
                <div className={`absolute`}>
                    <img src="/img/home/sec2_bg_img_1.png" alt="" className={`w-32`}/>
                </div>
                <div className={`absolute right-0 bottom-0`}>
                    <img src="/img/home/sec2_bg_img_2.png" alt="" className={`w-56`}/>
                </div>
                <p className={`font-semibold cormorant-font text-center mt-5 text-3xl md:text-5xl md:mb-5`}>
                    What We <span className={`main-text-color`}>Offer</span>
                </p>
                <div className={`container mx-auto hidden md:grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-7 md:gap-16`}>
                    {resources.map((resource, index) => (
                        <div
                            key={index}
                            className={`h-80 border flex flex-col justify-center items-center relative border-main bg-white hover:shadow-2xl cursor-pointer transition delay-150 mt-${(index % 2 + 1) * 20}`}
                        >
                            <div className={`rounded-full flex items-center justify-center absolute -mt-9 top-0 main-color p-3 h-16 w-16 text-white`}>
                                <p className={`items-center font-semibold text-xl`}>
                                    {index + 1}
                                </p>
                            </div>
                            <div className={`flex flex-col h-full justify-center items-center px-5 md:px-10`}>
                                <p className={`font-semibold text-xl self-start pb-5`}>{resource.title}</p>
                                <p>{resource.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={`container mx-auto md:hidden grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 mt-20 gap-7 md:gap-16`}>
                    {resources.map((resource, index) => (
                        <div
                            key={index}
                            className={`h-80 border flex flex-col justify-center items-center relative border-main bg-white hover:shadow-2xl cursor-pointer transition delay-150 mt-10`}
                        >
                            <div className={`rounded-full flex items-center justify-center absolute -mt-9 top-0 main-color p-3 h-16 w-16 text-white`}>
                                <p className={`items-center font-semibold text-xl`}>
                                    {index + 1}
                                </p>
                            </div>
                            <div className={`flex flex-col h-full justify-center items-center px-10 md:px-16`}>
                                <p className={`font-semibold text-xl self-start pb-5`}>{resource.title}</p>
                                <p>{resource.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={`container mx-auto min-h-screen-75 py-20 px-2 md:px-0`}>
                    <div className={`flex flex-col justify-center h-full`}>
                        <Parallax speed={3}>
                            <DesignFilter designs={DesignsPage} onFilter={handleFilter} />
                            <div className={`flex flex-col container mx-auto xl:w-10/12`}>
                                {filteredDesigns ?
                                <>
                                    {filteredDesigns.map((design, index) => (
                                        <div
                                            key={index}
                                            className={`flex flex-col-reverse md:flex-row gap-4 md:gap-7 lg:gap-16 xl:gap-20 py-6 md:py-16 h-full w-full items-center`}
                                        >
                                            <div className="flex md:w-6/12 relative w-full h-64">
                                                <div className={`absolute ${index % 2 !== 0 ? 'right-0 bottom-0':'left-0 top-0'}  `}>
                                                    <Parallax speed={1}>
                                                        <div
                                                            className={`w-56 h-44 md:w-56 md:h-64 lg:h-56 lg:w-96 xl:h-64 xl:w-96 ${index % 2 === 0 ? 'main-color':'menu-color'}`}
                                                        ></div>
                                                    </Parallax>
                                                </div>
                                                <div className={`absolute left-0 bottom-0 m-2 `}>
                                                    <img src={design.main_image} alt="" className={`shadow-2xl`}/>
                                                </div>
                                            </div>
                                            <div className="flex flex-col justify-between py-10 items-start md:w-6/12 px-2 h-96">
                                                <div>
                                                    <p className={`md:text-2xl font-bold tracking-normal pb-1 border-b border-main `}>
                                                        {design.title}
                                                    </p>

                                                    <p className={`tracking-normal py-3 text-gray-400`}>{design.square_foot} sq.m</p>
                                                    <p className={`tracking-normal py-3 text-gray-400`}>{truncateText(design.overview, 150)}</p>
                                                </div>
                                                <div className={`justify-start flex-col`}>
                                                    <div className="grid grid-cols-3 gap-7">
                                                        <div className={`flex flex-col`}>
                                                            <div className={`flex items-center gap-2 font-semibold text-gray-500`}>
                                                                <img src="/img/designs/ph_garage-light.png" alt="" className={`h-6`}/>
                                                                <p>Living Room</p>
                                                            </div>
                                                            {design.garage}
                                                        </div>
                                                        <div className={`flex flex-col`}>
                                                            <div className={`flex items-center gap-2 font-semibold text-gray-500`}>
                                                                <img src="/img/designs/bed-outline.png" alt="" className={`h-6`}/>
                                                                <p>Bedrooms</p>
                                                            </div>
                                                            {design.bedrooms}
                                                        </div>
                                                        <div className={`flex flex-col`}>
                                                            <div className={`flex items-center gap-2 font-semibold text-gray-500`}>
                                                                <img src="/img/designs/shower.png" alt="" className={`h-6`}/>
                                                                <p>Bathrooms</p>
                                                            </div>
                                                            {design.bathrooms}
                                                        </div>
                                                    </div>
                                                    <Link to={`/design/${design.slug}/${design.id}`}>
                                                        <p className={`font-bold tracking-normal w-48 pb-1 self-start main-color py-2 px-12 my-3`}>view more</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>:'No design with the filters'
                                }

                            </div>
                        </Parallax>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Designs;