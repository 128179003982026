import React, {createContext, Dispatch, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {fetchHomePage, fetchTestimonials} from "../features/home_page/homePageApiSlice";
import {fetchDesignsPage, fetchGovernance, fetchLocations} from "../features/design/designPageApiSlice";
import {fetchProjectsPage} from "../features/projects/projectsPageApiSlice";
import {fetchBlogsPage} from "../features/blog/blogPageApiSlice";


export type NavContextType = {
    homePageData: any;
    isLoadingHomePage: any;
    isLoadingTestimonial: any;
    DesignsPage: any;
    DesignFloorPlan: any;
    Locations: any;
    SingleLocation: any;
    governance: any;
    isSuccessContact: any;
    isErrorContact: any;
    messageHomePage: any;
    Design: any;
    Interest: any;
    isSuccessInterest: any;
    isErrorInterest: any;
    ProjectsPage: any;
    showMenu: boolean;
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
    framer_error: any;
}

// Initialize the context with an initial value
const NavContext = createContext<NavContextType | null>(null);

// Create the NavProvider component
interface NavProviderProps {
    children: React.ReactNode;
}

const NavProvider: React.FC<NavProviderProps> = ({ children }) => {
    // const user = useSelector((state: any) => state.user);
    const dispatch: Dispatch<any> = useDispatch()

    useEffect(() => {
        dispatch(fetchHomePage())
        dispatch(fetchTestimonials())
        dispatch(fetchDesignsPage())
        dispatch(fetchLocations())
        dispatch(fetchProjectsPage())
        dispatch(fetchGovernance())
    }, [])
    const { homePage, isLoadingHomePage, isLoadingTestimonial, isErrorContact, isSuccessContact, messageHomePage } = useSelector((state:any) => state.homePage)
    const { DesignsPage, Design, DesignFloorPlan, Locations, SingleLocation, Interest, isErrorInterest, isSuccessInterest, governance } = useSelector((state:any) => state.designPage)
    const { ProjectsPage } = useSelector((state:any) => state.projectsPage)
    const homePageData = homePage
    const [showMenu, setShowMenu] = useState(false);
    const framer_error = {
        initial: { opacity: 0, y: 10 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 10 },
        transition: { duration: 0.2 },
    };


    return <NavContext.Provider
        value={{
            isLoadingHomePage,
            isLoadingTestimonial,
            isSuccessContact,
            isErrorContact,
            Locations,
            DesignFloorPlan,
            homePageData,
            ProjectsPage,
            SingleLocation,
            messageHomePage,
            governance,
            Interest,
            isErrorInterest,
            isSuccessInterest,
            DesignsPage,
            Design,
            setShowMenu,
            showMenu,
            framer_error, }}>{children}</NavContext.Provider>;
};

export { NavContext, NavProvider };
