import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {RootState} from "../../app/store";
import ProjectsPageService from "./projectsPageApiService";




interface initState {
    ProjectsPage:any,
    isLoadingProjectsPage: boolean,
    isErrorProjectsPage: boolean,
    isSuccessProjectsPage: boolean,
    messageProjectsPage: any,
}

const initialState: initState = {
    ProjectsPage: [],
    isLoadingProjectsPage: false,
    isErrorProjectsPage: false,
    isSuccessProjectsPage: false,
    messageProjectsPage: "",
}


export const fetchProjectsPage = createAsyncThunk(
    'fetchProjectsPage',
    async (_, thunkAPI) => {
        try {
            return await ProjectsPageService.getProjectsPage()
        } catch (error:any) {
            // if the data.error exists loop through all the error and display the error in a comma sepereated string else return the data.message
            if (error.response && error.response.data && error.response.data.error) {
                const response = error.response.data;
                const errorFields = Object.keys(response.error);
                const errorMessages = errorFields.reduce((acc:any, field) => {
                    const fieldErrors = response.error[field];
                    return [...acc, ...fieldErrors];
                }, []);
                const errorMessage = errorMessages.join('');

                console.log('Errors:', errorMessage);
                throw new Error(errorMessage);


            } else if(error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);

            } else {
                throw new Error("An error occurred");
            }
        }
    }
)


export const ProjectsPageSlice = createSlice({
    name: 'ProjectsPageSlice',
    initialState,
    reducers: {
        DesignReset: (state) => {
            state.isLoadingProjectsPage = false
            state.isSuccessProjectsPage = false
            state.isErrorProjectsPage = false
            state.messageProjectsPage = ''
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjectsPage.pending, (state) => {
                state.isLoadingProjectsPage = true
            })
            .addCase(fetchProjectsPage.fulfilled, (state, action) => {
                state.isLoadingProjectsPage = false
                state.isSuccessProjectsPage = true
                state.ProjectsPage = action.payload
                state.messageProjectsPage = action.payload.message
            })
            .addCase(fetchProjectsPage.rejected, (state, action) => {
                state.isLoadingProjectsPage = false
                state.isErrorProjectsPage = true
                state.messageProjectsPage = action.error.message
                state.ProjectsPage = null
            })
    },
})

export const { DesignReset } = ProjectsPageSlice.actions
export default ProjectsPageSlice.reducer