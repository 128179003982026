import React, { useContext, useEffect, useRef, Suspense} from 'react';

import {useInView} from "framer-motion";
import {Link} from "react-router-dom";
import {NavContext, NavContextType} from "../../util/nav_context";
import { Parallax } from 'react-scroll-parallax';
import SearchBar from '../../components/searchbar/search';
import EarthHold from "../../components/earth/Earth_hold";
import {Fade, Slide, Zoom} from 'react-slideshow-image';
import Slideshow from "../../components/slider/simple";
import Opener from "../../components/opener/opener";
const Banner = React.lazy(() => import('./banner'));
const BestSelling = React.lazy(() => import('./bestSelling'))
const Welcome = React.lazy(() => import('./welcome'));
const Testimonial = React.lazy(() => import('./testimonial'));
Home2.propTypes = {

};


function Home2(props:any) {

    const targetRef = useRef<HTMLDivElement | null>(null);
    const ref5 = useRef(null);
    const isInView5 = useInView(ref5, { once: true });

    useEffect(() => {
        const updateMousePosition = (ev: MouseEvent) => {
            if (!targetRef.current) return;
            const { clientX, clientY } = ev;
            targetRef.current.style.setProperty("--x", `${clientX}px`);
            targetRef.current.style.setProperty("--y", `${clientY}px`);
        };

        window.addEventListener("mousemove", updateMousePosition);

        return () => {
            window.removeEventListener("mousemove", updateMousePosition);
        };
    }, []);



    const divStyle2 = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        height: '600px'
    }
    const slideImages = [
        "/img/home/new1.JPG",
        "/img/home/new2.JPG",
        "/img/home/new3.JPG",
    ];
    const slideImages2 = [
        "/img/home/new6.png",
        "/img/home/news3.png",
        // "/img/home/news4.png",
    ];
    const divStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        height: '400px'
    }

    return (
        <>
            {/*<Opener/>*/}
            <div className={``}>
                <Suspense
                    fallback={
                    <div className={`h-screen w-screen flex justify-center items-center`}>
                        <img src='/img/spinner.svg' className={`w-16`}/>
                    </div>}>
                    <Banner />
                </Suspense>
                <Suspense
                    fallback={
                    <div className={`h-screen w-screen flex justify-center items-center`}>
                        <img src='/img/spinner.svg' className={`w-16`}/>
                    </div>}>
                    <Welcome />
                </Suspense>
                <Suspense
                    fallback={
                    <div className={`h-screen w-screen flex justify-center items-center`}>
                        <img src='/img/spinner.svg' className={`w-16`}/>
                    </div>}>
                    <BestSelling />
                </Suspense>
                <Suspense
                    fallback={
                    <div className={`h-screen w-screen flex justify-center items-center`}>
                        <img src='/img/spinner.svg' className={`w-16`}/>
                    </div>}>
                    <Testimonial />
                </Suspense>





                <section ref={targetRef} className={`md:h-screen-75 relative menu-color`} style={{ backgroundImage: `url("/img/home/pattern.png")`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundBlendMode: 'color-dodge' }}>
                    <div className={`flex flex-col md:flex-row items-center h-full container mx-auto `}>
                        <div className={`w-full md:w-7/12`}>
                            <div className={`flex flex-col items-start`}>
                                <div className={`bg-white mt-5 p-6 ml-10 z-20`}>
                                    <p className={`font-semibold text-xl`}>
                                        Super Spacious Luxury <br/>Houses
                                    </p>
                                    <Link to="/designs">
                                        <span className={`main-text-color cursor-pointer`}>View more</span>
                                    </Link>
                                </div>
                                <div className={`w-full -mt-10 px-5 md:px-0`}>
                                    <Slideshow divStyle={divStyle} slideImages={slideImages} duration={3000}/>
                                </div>
                            </div>
                        </div>

                        <div className={`w-full md:w-5/12 md:-mt-44`}>
                            <div className={`flex flex-col items-start m-5 xl:m-24`}>
                                <div className={`h-full w-full`}>
                                    <Slideshow divStyle={divStyle2} slideImages={slideImages2} duration={3200}/>
                                </div>
                                <div className={`bg-white p-6 z-20 -mt-10 ml-10`}>
                                    <p className={`font-semibold text-xl`}>
                                        Super Squad <br/>Awesome Team
                                    </p>
                                    <Link to="/corporate-governance">
                                        <span className={`main-text-color cursor-pointer`}>View more</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mt-10 px-3 relative">
                    <div className="container mx-auto flex flex-col justify-center items-center">
                        <p className={`text-3xl md:text-4xl font-semibold cormorant-font pb-1`}>
                            <span className={`main-text-color`}>Subscribe</span> to our newsletter!
                        </p>
                        <p className={`md:text-xl pb-6 text-center`}>Be the first to learn new updates about rainbow heritage</p>
                        <SearchBar placeholder="name@example.com"  />
                    </div>
                </section>
                <section className={`h-screen relative overflow-hidden w-screen menu-color mt-40`}>
                    <div className={`absolute`}>
                        <img src="/img/home/sec2_bg_img_1.png" alt="" className={`w-32`}/>
                    </div>
                    <div className={`absolute right-0 top-72`}>
                        <img src="/img/home/sec2_bg_img_2.png" alt="" className={`w-56`}/>
                    </div>
                    <div className={`w-full h-screen `}>
                        <div className={`flex flex-col pt-14 h-full items-center`}>
                            <Parallax speed={5} className="items-center flex flex-col px-3">
                                <p className={`text-4xl flex items-center justify-center md:text-5xl tracking-normal main-text-color font-semibold cormorant-font`}>
                                    Excellence and Efficiency
                                </p>
                                <div className="w-full md:w-5/12 flex items-center justify-center">
                                    <p className={`md:text-2xl tracking-normal text-white text-center `}>
                                        We deliver project entrusted to us on time and within budget. Explore our locations both home and abroad.
                                    </p>
                                </div>
                            </Parallax>

                            <div className={`absolute w-full top-72`}>
                                <EarthHold/>
                            </div>

                        </div>
                    </div>
                </section>

                <section className={`container mx-auto px-3`}>
                    <div
                        ref={ref5}
                        style={{
                            transform: isInView5 ? "none" : "translateY(100px)",
                            opacity: isInView5 ? 1 : 0,
                            transition: "all 3s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                        }}
                        className={`flex w-full items-center justify-between py-10 md:py-20`}>
                        <div className={`flex flex-col md:flex-row items-center`}>
                            <div className={`md:w-6/12`}>
                                <img src="/img/home/blog.png" alt=""/>
                            </div>
                            <div className={`flex flex-col items-center justify-center text-center `}>
                                <div className={`md:w-8/12 flex flex-col items-center justify-center text-center`}>
                                    <p className={`font-semibold text-3xl md:mb-5`}>
                                        Check out our <span className={`main-text-color`}>Information Center!</span>
                                    </p>
                                    <p className={` md:mb-5`}>
                                        We’ve got exciting content and information for you
                                    </p>
                                    <Link to={`/blogs`}>
                                        <p className={`font-bold tracking-normal w-48 pb-1 self-start main-color py-2 px-12 my-3`}>Every info</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </section>
            </div>
        </>
    );
}

export default Home2;