import React, {Dispatch, useContext, useEffect, useRef, useState} from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {Subscribe} from "../../features/home_page/homePageApiSlice";

interface SearchBarProps {
  placeholder: string;
}



const SearchBar: React.FC<SearchBarProps> = ({ placeholder }) => {
  let input = useRef("");
  const dispatch: Dispatch<any> = useDispatch();
  const [formData, setFormData] = useState({
    email: '',
  })
  const onChange = (e:any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }
  const handleSubscribeSubmit = (e:any) => {
    e.preventDefault();
    const data = {
        email: formData.email,
    }
    const jsonData = JSON.stringify(data);
    dispatch(Subscribe(jsonData));
    console.log(jsonData);
  }


  return (
    <>
      <form onSubmit={handleSubscribeSubmit}>
        <div className="flex w-80 md:w-120 border rounded">
          <div className="relative w-full">
            <div className="relative">
              <input type="text" id="email" name="email"
                     onChange={onChange}
                     className="block w-full p-3 pl-5 text-sm text-gray-900 rounded bg-gray-50 focus:ring-green-500 focus:border-green-500 "
                     placeholder={placeholder} required />
                <button type="submit"
                        className="text-white absolute right-1 bottom-1 main-color hover:bg-sub2 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded text-sm px-4 py-2 ">Subscribe
                </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default SearchBar;
