import React, {useContext} from 'react';
import { motion,  useIsPresent} from 'framer-motion';
import {Parallax} from "react-scroll-parallax";
import {NavContext, NavContextType} from "../../util/nav_context";
import Contact from "../../components/contact/contact";
import WithScrollbar from "../../components/carousel/withScroll";
import Opener from "../../components/opener/opener";


Resources.propTypes = {

};

const resources = [
    {
        title: "Human Resources",
        description: "We Excel In Our Services Because We Ensure That All Our Operations And Management Personnel Are Reliable, Qualified And Competent\n" +
            "This Enhances Good Performance And Standard Workmanship"
    },
    {
        title: "Recruitments",
        description: "Our recruitment policy is open and at the same time focused, we know what we want and we go for it, our objectives never change, which is to deliver top quality service in a lean and efficient way"
    },
    {
        title: "Training",
        description: "A high premium is also attached to the training of all categories of our personnel to keep them abreast with improved technological techniques and skill as well as construction (material and equipment) products."
    },
    {
        title: "Experience",
        description: "Our institutional working experience in the Construction Sector spans over a ten (10) year period. It is collective and practicable. Rainbow Heritage also participates frequently in joint venture constructions in multiple markets, sharing risks and combining financial, technical, and other talents and resources to guarantee success"
    },
    {
        title: "Infrastructure",
        description: "Rainbow Heritage Group owns, maintains and operates basic operational infrastructure e.g. Jetty, mobile site offices, warehouses and workshops and yards with accompanying utilities."
    },
    {
        title: "Equipment",
        description: "Our Reliance and utilization of innovative state-of-the art construction tools and equipment enables us invest in quality equipment and tools by outright purchase, lease or hire depending on the prevailing economic advantage."
    }
]

function Resources(props:any) {


    return (
        <div>
            <Opener/>
            <section className={`relative`}>
                <div className={`menu-color h-screen-65`}>
                    <div className={`mx-auto h-screen-65 flex justify-center items-center`}>
                        <div className={`flex flex-col-reverse md:flex-row  mx-auto justify-center items-center h-full `}>
                            <div className={`md:w-5/12 px-3 pb-14 md:px-10`}>
                                <p className={`md:text-4xl font-bold mt-10 my pb-4 md:pb-14 tracking-normal text-white`}>
                                    Our Resources
                                </p>
                                <p className={`md:text-2xl w-full pb-4 tracking-normal text-white`}>
                                    We achieve excellence in our services by prioritizing top-quality and cost-efficient human, technical, and managerial resources.
                                </p>
                                {/*<Link to="/about">*/}
                                {/*    <button className={`main-color text-black text-md font-semibold px-10 md:px-20 py-2 rounded`}>*/}
                                {/*        About us*/}
                                {/*    </button>*/}
                                {/*</Link>*/}
                            </div>
                            <div className={`md:w-7/12 h-full w-full`} style={{ backgroundImage: `url('/img/resources/resources1.png')`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                                {/*<img src="/img/resources/resources1.png" alt="" className={`md:hidden`}/>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <WithScrollbar/>
            <section className={`min-h-screen-75 relative  `}>
                <div className={`absolute`}>
                    <img src="/img/home/sec2_bg_img_1.png" alt="" className={`w-32`}/>
                </div>
                <div className={`absolute right-0 bottom-0`}>
                    <img src="/img/home/sec2_bg_img_2.png" alt="" className={`w-56`}/>
                </div>
                <div className={`container mx-auto min-h-screen-75 py-20 px-2 md:px-0`}>
                    <div className={`flex flex-col justify-center h-full mt-10`}>
                        <div className={`flex flex-col`}>
                            <Parallax speed={3}>
                                <div className={`mx-auto text-base container text-center py-4 md:w-4/12`}>
                                    <p>
                                        We excel in our services because we ensure that our human, technical and managerial resources are top quality and cost efficient.
                                    </p>
                                </div>
                            </Parallax>
                        </div>
                        <div className={`hidden md:grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-7 md:gap-16`}>
                            {resources.map((resource, index) => (
                                <div
                                    key={index}
                                    className={`h-96 border flex flex-col justify-center items-center relative border-main bg-white hover:shadow-2xl cursor-pointer transition delay-150 mt-${(index % 2 + 1) * 20}`}
                                >
                                    <div className={`rounded-full flex items-center justify-center absolute -mt-9 top-0 main-color p-3 h-16 w-16 text-white`}>
                                        <p className={`items-center font-semibold text-xl`}>
                                            {index + 1}
                                        </p>
                                    </div>
                                    <div className={`flex flex-col h-full justify-center items-center px-10 md:px-16 text-lg`}>
                                        <p className={`font-semibold text-xl self-start pb-5`}>{resource.title}</p>
                                        <p>{resource.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={`md:hidden grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 mt-20 gap-7 md:gap-16`}>
                            {resources.map((resource, index) => (
                                <div
                                    key={index}
                                    className={`h-96 border flex flex-col justify-center items-center relative border-main bg-white hover:shadow-2xl cursor-pointer transition delay-150 mt-10`}
                                >
                                    <div className={`rounded-full flex items-center justify-center absolute -mt-9 top-0 main-color p-3 h-16 w-16 text-white`}>
                                        <p className={`items-center font-semibold text-xl`}>
                                            {index + 1}
                                        </p>
                                    </div>
                                    <div className={`flex flex-col h-full justify-center items-center px-10 md:px-16 text-lg`}>
                                        <p className={`font-semibold text-xl self-start pb-5`}>{resource.title}</p>
                                        <p>{resource.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Parallax speed={11}>
                            <div className={`flex flex-col container mx-auto md:w-10/12 mt-20`}>
                                <div className="flex flex-col-reverse md:flex-row center">
                                    <div className="flex flex-col py-10 justify-center md:w-6/12 px-2 md:px-20">
                                        <p className={`md:text-2xl font-bold tracking-normal`}>
                                            Equipment Policy
                                        </p>
                                        <p className={` tracking-normal `}>
                                            As a policy, the management of Rainbow Heritage Group ensures that all its operational equipment is suitable and reliable in terms of functionality, conditionality and capacity
                                        </p>
                                    </div>
                                    <div className="flex md:w-6/12">
                                        <img src="/img/resources/resources2.png" alt=""/>
                                    </div>
                                </div>
                                <div className="flex flex-col md:flex-row center">
                                    <div className="flex md:w-6/12">
                                        <img src="/img/resources/resources3.png" alt=""/>
                                    </div>
                                    <div className="flex flex-col py-10 justify-center md:w-6/12 px-2 md:px-20">
                                        <p className={` tracking-normal `}>
                                            Our Reliance and utilization of innovative state-of-the art construction tools and equipment enables us invest in quality equipment
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Parallax>
                    </div>

                </div>
            </section>
            <section className={`my-20 container mx-auto px-3`}>
                <Contact/>
            </section>
        </div>
    );
}

export default Resources;