import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";

Footer.propTypes = {

};

function Footer(props:any) {
    return (
        <div className={`w-full menu-color p-5 md:px-20 md:pt-20  text-white`} style={{ backgroundImage: `url("/img/home/pattern.png")`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
            <div className={`flex flex-col mb-10`}>
                <div className={`flex flex-col md:flex-row justify-between gap-10`}>
                    <img src="/img/footer/logo.png" alt="" className={`w-20 md:w-48`}/>
                </div>
                <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-10`}>
                    <div className={`grid grid-cols-2 justify-center `}>
                        <div className={`flex flex-col gap-5`}>
                            <Link to={`/`}>
                                <p className={`md:text-lg font-semibold cursor-pointer`}>Home</p>
                            </Link>
                            <Link to={`/about`}>
                                <p className={`md:text-lg font-semibold cursor-pointer`}>About Us</p>
                            </Link>
                            <Link to={`/corporate-governance`}>
                                <p className={`md:text-lg font-semibold cursor-pointer`}>Governance</p>
                            </Link>
                            <Link to={`/designs`}>
                                <p className={`md:text-lg font-semibold cursor-pointer`}>Designs</p>
                            </Link>
                            <Link to={`/contact`}>
                                <p className={`md:text-lg font-semibold cursor-pointer`}>Contact Us</p>
                            </Link>
                        </div>
                        <div className={`flex flex-col gap-5`}>
                            <Link to={`/projects`}>
                                <p className={`md:text-lg font-semibold cursor-pointer`}>Projects</p>
                            </Link>
                            <Link to={`/`}>
                                <p className={`md:text-lg font-semibold cursor-pointer`}>The RCE project</p>
                            </Link>
                            <Link to={`/blogs`}>
                                <p className={`text-lg font-semibold cursor-pointer`}>News</p>
                            </Link>
                        </div>
                    </div>
                    <div className={`flex justify-center`}>
                        <div>
                            <img src="/img/footer/location.png" alt="" className={`w-20`}/>
                        </div>
                        <div>
                            <div className={`ml-5 mb-5`}>
                                <p className={`md:text-lg font-semibold`}>1 Danjuma drive off Peter Odili Road, Port Harcourt</p>
                            </div>
                            <div className={`ml-5`}>
                                <p className={`md:text-lg font-semibold`}>
                                    Rainbow Heritage Project Services Ltd
                                    Biz Enterprise Centre
                                    Raunds
                                    Wellingborough
                                    NN9 6GR
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <div className={`flex flex-col gap-5`}>
                            <div className={`flex items-center`}>
                                <img src="/img/footer/phone.png" alt="" className={`w-6`}/>
                                <div>
                                    <div className={`ml-2`}>
                                        <p className={`md:text-lg font-semibold`}>
                                            +234 9156494179, +234 9156494171
                                        </p>
                                        <p className={`md:text-lg font-semibold`}>
                                            +44 79 3202 8701, +45 79 8334 6211
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={`flex items-center `}>
                                <img src="/img/footer/sms.png" alt="" className={`w-6`}/>
                                <div>
                                    <div className={`ml-2`}>
                                        <p className={`md:text-lg font-semibold`}>
                                            contact@rainbowheritage.com.ng
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={`flex items-center gap-6 `}>
                                <Link to={`/`}>
                                    <img src="/img/footer/twitter.png" alt="" className={`w-6`}/>
                                </Link>
                                <Link to={`/`}>
                                    <img src="/img/footer/facebook.png" alt="" className={`w-4`}/>
                                </Link>
                                <Link to={`/`}>
                                    <img src="/img/footer/linkedin.png" alt="" className={`w-6`}/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`flex justify-end md:pt-20`}>
                    <Link to={`/`}>
                        <p className={`text-sm cursor-pointer`}>SITE DESIGNED BY VEDIESTUDIO</p>
                    </Link>
                </div>
            </div>

        </div>
    );
}

export default Footer;