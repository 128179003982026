import React from 'react';
import { Slide, Zoom, Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const spanStyle = {
    padding: '20px',
    color: '#000000'
}

interface SliderProps {
    slideImages: string[];
    divStyle: any;
    duration?: number;
}



const Slideshow: React.FC<SliderProps> = ({slideImages, divStyle, duration }) => {
    return (
        <div className="slide-container">
            <Fade autoplay={true} arrows={false} transitionDuration={500} duration={duration}>
                {slideImages.map((slideImage, index)=> (
                    <div key={index}>
                        <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage})` }}>
                            <span style={spanStyle}></span>
                        </div>
                    </div>
                ))}
            </Fade>
        </div>
    )
}

export default Slideshow;