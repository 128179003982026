import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {RootState} from "../../app/store";
import DesignsPageService from "./designPageApiService";




interface initState {
    DesignsPage:any,
    Design:any,
    DesignFloorPlan:any,
    Locations:any,
    Interest:any,
    SingleLocation:any,
    governance:any,
    isLoadingDesignsPage: boolean,
    isErrorDesignsPage: boolean,
    isErrorInterest: boolean,
    isSuccessInterest: boolean,
    isSuccessDesignsPage: boolean,
    messageDesignsPage: any,
}

const initialState: initState = {
    DesignsPage: [],
    Design: {},
    DesignFloorPlan: [],
    Locations: [],
    SingleLocation: {},
    governance: [],
    isLoadingDesignsPage: false,
    isErrorDesignsPage: false,
    isSuccessDesignsPage: false,
    Interest: {},
    isErrorInterest: false,
    isSuccessInterest: false,
    messageDesignsPage: "",
}


export const fetchDesignsPage = createAsyncThunk(
    'fetchDesignsPage',
    async (_, thunkAPI) => {
        try {
            return await DesignsPageService.getDesignsPage()
        } catch (error:any) {
            // if the data.error exists loop through all the error and display the error in a comma sepereated string else return the data.message
            if (error.response && error.response.data && error.response.data.error) {
                const response = error.response.data;
                const errorFields = Object.keys(response.error);
                const errorMessages = errorFields.reduce((acc:any, field) => {
                    const fieldErrors = response.error[field];
                    return [...acc, ...fieldErrors];
                }, []);
                const errorMessage = errorMessages.join('');

                console.log('Errors:', errorMessage);
                throw new Error(errorMessage);


            } else if(error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);

            } else {
                throw new Error("An error occurred");
            }
        }
    }
)

export const fetchDesign = createAsyncThunk(
    'fetchDesign',
    async (data:any) => {
        try {
            return await DesignsPageService.getSingleDesign(data)
        } catch (error:any) {
            // if the data.error exists loop through all the error and display the error in a comma sepereated string else return the data.message
            if (error.response && error.response.data && error.response.data.error) {
                const response = error.response.data;
                const errorFields = Object.keys(response.error);
                const errorMessages = errorFields.reduce((acc:any, field) => {
                    const fieldErrors = response.error[field];
                    return [...acc, ...fieldErrors];
                }, []);
                const errorMessage = errorMessages.join('');

                console.log('Errors:', errorMessage);
                throw new Error(errorMessage);

            } else if(error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);

            } else {
                throw new Error("An error occurred");
            }
        }
    }
)

export const fetchDesignFloorPlan = createAsyncThunk(
    'fetchDesignFloorPlan',
    async (data:any) => {
        try {
            return await DesignsPageService.getSingleDesignFloorPlan(data)
        } catch (error:any) {
            // if the data.error exists loop through all the error and display the error in a comma sepereated string else return the data.message
            if (error.response && error.response.data && error.response.data.error) {
                const response = error.response.data;
                const errorFields = Object.keys(response.error);
                const errorMessages = errorFields.reduce((acc:any, field) => {
                    const fieldErrors = response.error[field];
                    return [...acc, ...fieldErrors];
                }, []);
                const errorMessage = errorMessages.join('');

                console.log('Errors:', errorMessage);
                throw new Error(errorMessage);

            } else if(error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);

            } else {
                throw new Error("An error occurred");
            }
        }
    }
)


export const fetchLocations = createAsyncThunk(
    'fetchLocations',
    async (_, thunkAPI) => {
        try {
            return await DesignsPageService.getLocations()
        } catch (error:any) {
            // if the data.error exists loop through all the error and display the error in a comma sepereated string else return the data.message
            if (error.response && error.response.data && error.response.data.error) {
                const response = error.response.data;
                const errorFields = Object.keys(response.error);
                const errorMessages = errorFields.reduce((acc:any, field) => {
                    const fieldErrors = response.error[field];
                    return [...acc, ...fieldErrors];
                }, []);
                const errorMessage = errorMessages.join('');

                console.log('Errors:', errorMessage);
                throw new Error(errorMessage);


            } else if(error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);

            } else {
                throw new Error("An error occurred");
            }
        }
    }
)

export const fetchSingleLocation = createAsyncThunk(
    'fetchSingleLocation',
    async (data:any) => {
        try {
            return await DesignsPageService.getSingleLocation(data)
        } catch (error:any) {
            // if the data.error exists loop through all the error and display the error in a comma sepereated string else return the data.message
            if (error.response && error.response.data && error.response.data.error) {
                const response = error.response.data;
                const errorFields = Object.keys(response.error);
                const errorMessages = errorFields.reduce((acc:any, field) => {
                    const fieldErrors = response.error[field];
                    return [...acc, ...fieldErrors];
                }, []);
                const errorMessage = errorMessages.join('');

                console.log('Errors:', errorMessage);
                throw new Error(errorMessage);

            } else if(error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);

            } else {
                throw new Error("An error occurred");
            }
        }
    }
)

export const PostDesignInterest = createAsyncThunk(
    'PostDesignInterest',
    async (data:any) => {
        try {
            return await DesignsPageService.postInterest(data)
        } catch (error:any) {
            // if the data.error exists loop through all the error and display the error in a comma sepereated string else return the data.message
            if (error.response && error.response.data && error.response.data.error) {
                const response = error.response.data;
                const errorFields = Object.keys(response.error);
                const errorMessages = errorFields.reduce((acc:any, field) => {
                    const fieldErrors = response.error[field];
                    return [...acc, ...fieldErrors];
                }, []);
                const errorMessage = errorMessages.join('');

                console.log('Errors:', errorMessage);
                throw new Error(errorMessage);

            } else if(error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);

            } else {
                throw new Error("An error occurred");
            }
        }
    }
)

export const fetchGovernance = createAsyncThunk(
    'fetchGovernance',
    async (_, thunkAPI) => {
        try {
            return await DesignsPageService.getGovernance()
        } catch (error:any) {
            // if the data.error exists loop through all the error and display the error in a comma sepereated string else return the data.message
            if (error.response && error.response.data && error.response.data.error) {
                const response = error.response.data;
                const errorFields = Object.keys(response.error);
                const errorMessages = errorFields.reduce((acc:any, field) => {
                    const fieldErrors = response.error[field];
                    return [...acc, ...fieldErrors];
                }, []);
                const errorMessage = errorMessages.join('');

                console.log('Errors:', errorMessage);
                throw new Error(errorMessage);


            } else if(error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);

            } else {
                throw new Error("An error occurred");
            }
        }
    }
)
export const designsPageSlice = createSlice({
    name: 'designsPageSlice',
    initialState,
    reducers: {
        DesignReset: (state) => {
            state.isLoadingDesignsPage = false
            state.isSuccessDesignsPage = false
            state.isErrorDesignsPage = false
            state.isErrorInterest = false
            state.isSuccessInterest = false
            state.messageDesignsPage = ''
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDesignsPage.pending, (state) => {
                state.isLoadingDesignsPage = true
            })
            .addCase(fetchDesignsPage.fulfilled, (state, action) => {
                state.isLoadingDesignsPage = false
                state.isSuccessDesignsPage = true
                state.DesignsPage = action.payload
                state.messageDesignsPage = action.payload.message
            })
            .addCase(fetchDesignsPage.rejected, (state, action) => {
                state.isLoadingDesignsPage = false
                state.isErrorDesignsPage = true
                state.messageDesignsPage = action.error.message
                state.DesignsPage = null
            })
            .addCase(fetchDesign.pending, (state) => {
                state.isLoadingDesignsPage = true
            })
            .addCase(fetchDesign.fulfilled, (state, action) => {
                state.isLoadingDesignsPage = false
                state.isSuccessDesignsPage = true
                state.Design = action.payload
                state.messageDesignsPage = action.payload.message
            })
            .addCase(fetchDesign.rejected, (state, action) => {
                state.isLoadingDesignsPage = false
                state.isErrorDesignsPage = true
                state.messageDesignsPage = action.error.message
                state.Design = null
            })
            .addCase(fetchDesignFloorPlan.pending, (state) => {
                state.isLoadingDesignsPage = true
            })
            .addCase(fetchDesignFloorPlan.fulfilled, (state, action) => {
                state.isLoadingDesignsPage = false
                state.isSuccessDesignsPage = true
                state.DesignFloorPlan = action.payload
                state.messageDesignsPage = action.payload.message
            })
            .addCase(fetchDesignFloorPlan.rejected, (state, action) => {
                state.isLoadingDesignsPage = false
                state.isErrorDesignsPage = true
                state.messageDesignsPage = action.error.message
                state.Design = null
            })
            .addCase(fetchLocations.pending, (state) => {
                state.isLoadingDesignsPage = true
            })
            .addCase(fetchLocations.fulfilled, (state, action) => {
                state.isLoadingDesignsPage = false
                state.isSuccessDesignsPage = true
                state.Locations = action.payload
                state.messageDesignsPage = action.payload.message
            })
            .addCase(fetchLocations.rejected, (state, action) => {
                state.isLoadingDesignsPage = false
                state.isErrorDesignsPage = true
                state.messageDesignsPage = action.error.message
                state.Locations = null
            })
            .addCase(fetchSingleLocation.pending, (state) => {
                state.isLoadingDesignsPage = true
            })
            .addCase(fetchSingleLocation.fulfilled, (state, action) => {
                state.isLoadingDesignsPage = false
                state.isSuccessDesignsPage = true
                state.SingleLocation = action.payload
                state.messageDesignsPage = action.payload.message
            })
            .addCase(fetchSingleLocation.rejected, (state, action) => {
                state.isLoadingDesignsPage = false
                state.isErrorDesignsPage = true
                state.messageDesignsPage = action.error.message
                state.SingleLocation = null
            })
            .addCase(PostDesignInterest.pending, (state) => {
                state.isLoadingDesignsPage = true
            })
            .addCase(PostDesignInterest.fulfilled, (state, action) => {
                state.isLoadingDesignsPage = false
                state.isSuccessInterest = true
                state.Interest = action.payload
                state.messageDesignsPage = action.payload.message
            })
            .addCase(PostDesignInterest.rejected, (state, action) => {
                state.isLoadingDesignsPage = false
                state.isErrorInterest = true
                state.messageDesignsPage = action.error.message
                state.Interest = null
            })
            .addCase(fetchGovernance.pending, (state) => {
                state.isLoadingDesignsPage = true
            })
            .addCase(fetchGovernance.fulfilled, (state, action) => {
                state.isLoadingDesignsPage = false
                // state.isSuccessInterest = true
                state.governance = action.payload
                state.messageDesignsPage = action.payload.message
            })
            .addCase(fetchGovernance.rejected, (state, action) => {
                state.isLoadingDesignsPage = false
                state.isErrorInterest = true
                state.messageDesignsPage = action.error.message
                state.Interest = null
            })
    },
})

export const { DesignReset } = designsPageSlice.actions
export default designsPageSlice.reducer