import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom'
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import 'react-multi-carousel/lib/styles.css';
import Home from "./pages/home/home";
import Nav from "./components/nav/nav";
import About from "./pages/about/about";
import Footer from "./components/footer/footer";
import NotFound from "./pages/not_found/not_found";
import ContactPage from "./pages/contact_page/contact_page";
import Companies from "./pages/companies/companies";
import Designs from "./pages/designs/designs";
import Resources from "./pages/resourses/resources";
import Home2 from "./pages/home2/home2";
import SingleDesign from "./pages/designs/single_design";
import Blog from "./pages/blog/blog";
import SingleBlog from "./pages/blog/single_blog";
import Governance from "./pages/governance/governance";
import ScrollToTop from "./components/scrollToTop/scrollToTop";

function App() {
  return (
      <>
        <Router>
            <ScrollToTop/>
          <div className=''>
            <Routes>
              <Route  element={(
                  <>
                    <Nav />
                    <Outlet />
                  </>
              )}>
                <Route path='/' element={<Home2 />} />
                <Route path='/home' element={<Home2 />} />
                <Route path='/about' element={<About />} />
                <Route path='/resources' element={<Resources />} />
                <Route path='/corporate-governance' element={<Governance />} />
                <Route path='/contact' element={<ContactPage />} />
                <Route path='/projects' element={<Companies />} />
                <Route path='/designs' element={<Designs />} />
                <Route path='/design/:slug/:id' element={<SingleDesign />} />
                <Route path='/blogs' element={<Blog />} />
                <Route path='/blog/:slug/' element={<SingleBlog />} />
              </Route>

              {/* 404 route */}
              <Route path='*' element={<NotFound />} />

            </Routes>
            <Footer />
          </div>
        </Router>
        <ToastContainer />
      </>

  );
}

export default App;
