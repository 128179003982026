import React, {Suspense, useContext, useState} from 'react';
import PropTypes from 'prop-types';
import Earth from './Earth';
import {Canvas} from "@react-three/fiber";
import {XMarkIcon} from "@heroicons/react/24/solid";
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Fragment } from 'react'
import {NavContext, NavContextType} from "../../util/nav_context";
import {useSelector} from "react-redux";


EarthHold.propTypes = {

};

interface Location {
    latitude: number;
    longitude: number;
    title: string;
    state: string;
    type: string;
    img: string;
    color?: string;
}
//
// const locations: Location[] = [
//     { lat: 9.072264, lon: 7.491302, name: 'Sigma smart estate', location: 'Abuja, Nigeria', color: "#CA942B", type:"Estate", img:"/img/home/tttt.JPG" }, // Example location at latitude 30 and longitude 45
//     { lat: 6.465422, lon: 3.406448, name: 'Lagos smart estate', location: 'Lagos, Nigeria', color: "#CA942B", type:"Estate", img:"/img/home/tttt.JPG"  }, // Example location at latitude 30 and longitude 45
//     { lat: 4.824167, lon:  7.033611, name: 'Port Harcourt smart estate', location: 'Port Harcourt, Nigeria', color: "#CA942B", type:"Estate", img:"/img/home/tttt.JPG"  }, // Example location at latitude 30 and longitude 45
//     { lat: 51.253777, lon: -85.323212, name: 'eon exclusive estate', location: 'Onatrio, Canada', color:"#ff0000", type:"Estate", img:"/img/home/tttt.JPG"  }, // Example location at latitude 30 and longitude 45
//     // Add more locations here...
// ];


const LocationPopup: React.FC<{ name: string; type: string; lon: string; lat: string; img: string; location: string; onClose: () => void }> = (
    { name, location, onClose, type, lon, lat, img }) => (
    <div className="rounded shadow-2xl shadow location-popup bg-white text-black flex flex-col justify-center items-center text-2xl w-full md:w-120 mb-10  transition duration-150 ease-in-out">
        <div className={`flex md:flex-row flex-col-reverse w-full`}>
            <div className="p-10 md:w-7/12 flex flex-col  justify-between relative h-full">
                <p className={`text-sm `}>{location}</p>
                <h3 className={`cormorant-font text-5xl my-7`}>{name}</h3>

                <p className={`text-sm mb-4`}>{type}</p>
                <div className="flex text-sm">
                    <p>{lat}</p>,
                    <p>{lon}</p>
                </div>
                <div className="flex mt-10">
                    <button className="bg-main py-3 main-text-color border-b-2 border-b-main pb-1 text-sm cursor-pointer">View Project</button>
                </div>

            </div>
            <div className="relative h-full w-full md:w-5/12">
                <div className="absolute rounded-full bg-white p-1 m-1 right-0 cursor-pointer transition-transform duration-1000 transform hover:rotate-90" onClick={onClose}>
                    <XMarkIcon className="w-6 text-color-dark" aria-hidden="true" />
                </div>
                <div className="md:h-120" style={{ backgroundImage: `url(${img})`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
            </div>
 </div>



    </div>
);

function EarthHold(props: any) {
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);

    const { Locations } = useSelector((state:any) => state.designPage)
    const locations: Location[] = Locations;

    const handleLocationClick = (location: Location) => {
        setSelectedLocation(location);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    return (
        <div className="earth relative flex flex-col h-screen cursor-grab">
            <Canvas>
                <Suspense fallback={null}>
                    <Earth
                        showPopup={showPopup}
                        selectedLocation={selectedLocation}
                        onLocationClick={handleLocationClick}
                        onClosePopup={handleClosePopup}
                    />
                </Suspense>
            </Canvas>
            <div className=" rounded absolute md:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <div className="w-full max-w-sm px-4">
                    <Popover className="relative">
                        {({ open }) => (
                            <>
                                <Popover.Button
                                    className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex items-center rounded bg-white px-3 py-2 text-base font-medium text-gray-600 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                                >
                                    <span className={`md:mr-36`}>Choose a Project</span>
                                    <ChevronDownIcon
                                        className={`${open ? '' : 'text-opacity-70'}
                  ml-2 h-5 w-5 text-orange-300 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                                        aria-hidden="true"
                                    />
                                </Popover.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                >
                                    <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                            <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
                                                {locations.map((location, index) => {
                                                    const { latitude, longitude,color } = location;

                                                    return (
                                                        <div
                                                            key={index}
                                                            className={`cursor-pointer${color}`}
                                                            onClick={() => handleLocationClick(location)}
                                                        >
                                                            <p className={`cursor-pointer`}><span className={`rounded-full bg-red h-4 w-4 mr-1`}></span>{location.title}</p>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </Popover.Panel>
                                </Transition>
                            </>
                        )}
                    </Popover>
                </div>
            </div>
            {showPopup && selectedLocation && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full px-3">
                    <LocationPopup
                        name={selectedLocation.title}
                        location={selectedLocation.state}
                        onClose={handleClosePopup}
                        type={selectedLocation.type}
                        lon={selectedLocation.longitude.toString()}
                        lat={selectedLocation.latitude.toString()}
                        img={selectedLocation.img}
                    />
                </div>
            )}
        </div>
    );
}

export default EarthHold;

