import React, {Dispatch, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {motion, useAnimation, useIsPresent, AnimatePresence} from 'framer-motion';
import {Bars4Icon, PhoneIcon, XMarkIcon} from "@heroicons/react/24/solid";
import {NavContextType, NavContext} from "../../util/nav_context";
import {Link} from "react-router-dom";
import useScroll from "../useScroll";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {reset} from "../../features/home_page/homePageApiSlice";
import { DesignReset } from '../../features/design/designPageApiSlice';
import {BookOpenIcon} from "@heroicons/react/20/solid";
Nav.propTypes = {

};

function Nav(props:any) {
    const navContextData = useContext(NavContext) as NavContextType
    const { isErrorContact, isSuccessContact, messageHomePage, showMenu, setShowMenu} = navContextData
    const { isSuccessInterest, isErrorInterest } = navContextData
    const controls = useAnimation();
    const isPresent = useIsPresent();
    const [navClassList, setNavClassList] = useState<string[]>([]);
    const scroll = useScroll();
    const dispatch: Dispatch<any> = useDispatch();

    // update classList of nav on scroll
    useEffect(() => {
        const _classList = [];

        if (scroll.y > 250 && scroll.y - scroll.lastY > 0)
            _classList.push("nav-bar--hidden");
        setNavClassList(_classList);
    }, [scroll.y, scroll.lastY]);


    if (isSuccessContact) {
        toast.success("Message Sent Successfully");
        dispatch(reset());
    }
    if (isErrorContact) {
        toast.error(messageHomePage);
        dispatch(reset());
    }

    if (isSuccessInterest) {
        toast.success("Interest Sent Successfully");
        dispatch(DesignReset());
    }
    if (isErrorInterest) {
        toast.error("an error occurred");
        dispatch(DesignReset());
    }


    const menuVariants = {
        open: { x: 0 }, // Slide in from the right
        closed: { x: '100%' }, // Off-screen to the right
    };

    useEffect(() => {
        if (!showMenu) {
            // If the menu is closing, set a delay before starting the closing animation
            setTimeout(() => {
                controls.start('closed');
            }, 300); // Adjust the delay duration as needed
        } else {
            controls.start('open');
        }
    }, [controls, showMenu]);
    return (
        <div className={`mb-24`}>
            <nav className={navClassList.join(" z-20 ")}>
                <AnimatePresence>
                    {showMenu &&
                        <motion.div
                            className={`flex justify-between z-50 absolute top-0 left-0 h-screen w-screen lg:w-screen-75 `}
                            initial="closed"
                            animate={controls}
                            exit={{
                                opacity:0,
                                y:90,
                                transition:{
                                    ease:"easeInOut",
                                    delay:.1
                                }
                            }}
                            variants={menuVariants}
                            transition={{ duration: 0.7 }}
                        >
                            <motion.div
                                initial={{ scaleX: 1 }}
                                animate={{ scaleX: 0, transition: { duration: 1, ease: "circOut" } }}
                                exit={{ scaleX: 1, transition: { duration: 3, ease: "circIn" } }}
                                style={{ originX: isPresent ? 0 : 1 }}
                                className="privacy-screen h-screen w-screen "
                            >
                            </motion.div>
                            <div className={`absolute h-screen w-screen z-50`}>
                                <div className={`flex justify-end items-center relative`}>
                                    <div>
                                        <div className={`absolute rounded-full border-amber-500 border-2 w-56 h-56 -ml-28 -mt-24 items-center transition-transform duration-1000 transform hover:rotate-90  flex justify-center cursor-pointer hover:bg-menu`} onClick={()=>setShowMenu(!setShowMenu)}>
                                            <div className={`flex items-center `}>
                                                <XMarkIcon className="w-8 main-text-color" aria-hidden="true" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`menu-color min-h-screen scroll-auto`} onClick={()=>setShowMenu(!setShowMenu)}>
                                        <div className={`py-5 px-3 md:py-14 md:pl-32 md:pr-24`}>
                                            <div className={`flex justify-between md:gap-14`}>
                                                <div className="flex flex-col justify-between gap-8">
                                                    <div className={`flex items-start md:flex hidden`}>
                                                        <Link to={"/"}>
                                                            <p className={`main-text-color lg:text-3xl border-b-2 border-b-main pb-1 cursor-pointer`}>
                                                                Homepage
                                                            </p>
                                                        </Link>
                                                    </div>

                                                    <div className={`flex hidden md:flex`}>
                                                        <div className={`border border-r-gray-300 p-1 lg:p-5 w-10 lg:w-20 flex items-center justify-center`}>
                                                            <img src="/img/nav/twitter.png" alt="" className={`w-4 lg:w-8`}/>
                                                        </div>
                                                        <div className={`border border-r-gray-300 p-1 lg:p-5 w-10 lg:w-20 flex items-center justify-center`}>
                                                            <img src="/img/nav/facebook.png" alt="" className={`w-2 lg:w-4`}/>
                                                        </div>
                                                        <div className={`border border-r-gray-300 p-1 lg:p-5 w-10 lg:w-20 flex items-center justify-center`}>
                                                            <img src="/img/nav/linkedin.png" alt="" className={`w-4 lg:w-8`}/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <motion.div
                                                    className={`flex flex-col justify-end items-end gap-8`}
                                                    initial="closed"
                                                    animate={controls}
                                                    variants={menuVariants}
                                                    transition={{ duration: 0.9 }}
                                                >
                                                    <div className="flex justify-between w-full mb-20 md:mb-0">
                                                        <div className={`flex items-start md:hidden `}>
                                                            <Link to={"/"}>
                                                                <p className={`main-text-color text-xl border-b-2 border-b-main pb-1 cursor-pointer`}>
                                                                    Homepage
                                                                </p>
                                                            </Link>
                                                        </div>
                                                        <div className={`cursor-pointer flex items-end transition-transform duration-1000 transform hover:rotate-90`} onClick={() => setShowMenu(!showMenu)}>
                                                            <XMarkIcon className="w-8 text-color-dark" aria-hidden="true" />
                                                        </div>
                                                    </div>

                                                    {/*<motion.div*/}
                                                    {/*    initial="closed"*/}
                                                    {/*    animate={controls}*/}
                                                    {/*    variants={menuVariants}*/}
                                                    {/*    transition={{ duration: 1 }}*/}
                                                    {/*>*/}
                                                    {/*    <Link to={"/home"}>*/}
                                                    {/*        <p className={`text-white cursor-pointer text-3xl transition-transform duration-1000 transform hover:rotate-2`}>*/}
                                                    {/*            Explore Locations*/}
                                                    {/*        </p>*/}
                                                    {/*    </Link>*/}
                                                    {/*</motion.div>*/}
                                                    <motion.div
                                                        initial="closed"
                                                        animate={controls}
                                                        variants={menuVariants}
                                                        transition={{ duration: 1.3 }}
                                                    >
                                                        <Link to={"/about"}>
                                                            <p className={`text-white cursor-pointer text-3xl transition-transform duration-1000 transform hover:rotate-2`}>
                                                                About Rainbow Heritage
                                                            </p>
                                                        </Link>
                                                    </motion.div>
                                                    <motion.div
                                                        initial="closed"
                                                        animate={controls}
                                                        variants={menuVariants}
                                                        transition={{ duration: 1.1 }}
                                                    >
                                                        <Link to={"/projects"}>
                                                            <p className={`text-white cursor-pointer text-3xl transition-transform duration-1000 transform hover:rotate-2`}>
                                                                Our Projects
                                                            </p>
                                                        </Link>
                                                    </motion.div>
                                                    <motion.div
                                                        initial="closed"
                                                        animate={controls}
                                                        variants={menuVariants}
                                                        transition={{ duration: 1.5 }}
                                                    >
                                                        <Link to={"/corporate-governance"}>
                                                            <p className={`text-white cursor-pointer text-3xl transition-transform duration-1000 transform hover:rotate-2`}>
                                                                Corporate Governance
                                                            </p>
                                                        </Link>
                                                    </motion.div>

                                                    <motion.div
                                                        initial="closed"
                                                        animate={controls}
                                                        variants={menuVariants}
                                                        transition={{ duration: 1.4 }}
                                                    >
                                                        <Link to={"/designs"}>
                                                            <p className={`text-white cursor-pointer text-3xl transition-transform duration-1000 transform hover:rotate-2`}>
                                                                Our Products
                                                            </p>
                                                        </Link>
                                                    </motion.div>

                                                    <motion.div
                                                        initial="closed"
                                                        animate={controls}
                                                        variants={menuVariants}
                                                        transition={{ duration: 1.2 }}
                                                    >
                                                        <Link to={"/blogs"}>
                                                            <p className={`text-white cursor-pointer text-3xl transition-transform duration-1000 transform hover:rotate-2`}>
                                                                Information Desk
                                                            </p>
                                                        </Link>
                                                    </motion.div>
                                                    <motion.div
                                                        initial="closed"
                                                        animate={controls}
                                                        variants={menuVariants}
                                                        transition={{ duration: 1.6 }}
                                                    >
                                                        <Link to={"/contact"}>
                                                            <p className={`text-white cursor-pointer text-3xl transition-transform duration-1000 transform hover:rotate-2`}>
                                                                Contact Us
                                                            </p>
                                                        </Link>
                                                    </motion.div>
                                                    <motion.div className={`flex flex-col gap-5 mt-20 md:mt-0 items-start`}
                                                                initial="closed"
                                                                animate={controls}
                                                                variants={menuVariants}
                                                                transition={{ duration: 1.7 }}
                                                    >
                                                        <div className={`flex md:hidden`}>
                                                            <div className={`border border-r-gray-300 p-5 w-20 flex items-center justify-center`}>
                                                                <img src="/img/nav/twitter.png" alt="" className={`w-8`}/>
                                                            </div>
                                                            <div className={`border border-r-gray-300 p-5 w-20 flex items-center justify-center`}>
                                                                <img src="/img/nav/facebook.png" alt="" className={`w-4`}/>
                                                            </div>
                                                            <div className={`border border-r-gray-300 p-5 w-20 flex items-center justify-center`}>
                                                                <img src="/img/nav/linkedin.png" alt="" className={`w-8`}/>
                                                            </div>
                                                        </div>
                                                        <div className={`flex flex-col justify-start md:justify-end items-start md:items-end text-color-dark text-lg lg:text-3xl`}>
                                                            <p>contact@rainbowheritage.com.ng</p>
                                                            <p>+234 091 5649 4179,</p>
                                                            <p>+234 091 5649 4171,</p>
                                                            <p>+44 79 3202 8701,</p>
                                                            <p>+45 79 8334 6211</p>
                                                        </div>
                                                    </motion.div>
                                                </motion.div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </motion.div>
                    }
                    <div className={`bg-white flex justify-between container mx-auto px-4 md:px-0`}>
                        <div className={`border-r border-main pr-10 cursor-pointer`}>
                            <Link to={`/`}>
                                <img src="/img/nav/logo.png" alt="" className={`py-5  w-28 `} />
                            </Link>
                        </div>
                        <div className={`grid grid-cols-2 justify-end`}>
                            <div className={`border-r border-main pr-8 md:pr-10 `}>
                                <div className={`flex py-3 items-center justify-center h-full gap-2`}>
                                    <div className={`main-color h-8 w-8 flex justify-center items-center rounded-full`}>
                                        <a href="brochure.pdf" target='_blank'><BookOpenIcon className={`w-5`}/></a>
                                    </div>
                                    <p className={`md:mr-2 hidden md:block`}><a href="brochure.pdf" target='_blank'>BILL</a></p>
                                    <div className={`main-color h-8 w-8 cursor-pointer justify-center items-center rounded-full flex`}>
                                        <Link to="tel:2349156494171"> <PhoneIcon className={`w-5`}/></Link>
                                    </div>
                                    <p className={`hidden md:block`}>CALL</p>
                                </div>
                            </div>
                            <div className={`justify-end py-3 flex items-center gap-2 cursor-pointer `}
                                 onClick={() => setShowMenu(!showMenu)}
                            >
                                <Bars4Icon className={`w-6`}/>
                                <p>MENU</p>
                            </div>
                        </div>
                    </div>
                </AnimatePresence>
            </nav>

        </div>
    );
}

export default Nav;