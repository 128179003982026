import React, {Dispatch, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {AnimatePresence, motion, useAnimation, useIsPresent} from 'framer-motion';
import {Parallax} from "react-scroll-parallax";
import {Link, useParams} from "react-router-dom";
import {NavContext, NavContextType} from "../../util/nav_context";
import {PlusIcon} from "@heroicons/react/24/solid";
import Slider from "../../components/slider/slider";
import {fetchDesign, fetchDesignFloorPlan, fetchSingleLocation} from "../../features/design/designPageApiSlice";
import {useDispatch} from "react-redux";
import Slideshow from "../../components/slider/simple";
import {Fade, Slide} from "react-slideshow-image";
import InterestForm from "../../components/searchbar/interest";


SingleDesign.propTypes = {

};

function SingleDesign(props:any) {
    const { slug, id } = useParams();
    const isPresent = useIsPresent();
    const navContextData = useContext(NavContext) as NavContextType
    const { Design, DesignFloorPlan, SingleLocation } = navContextData

    const dispatch: Dispatch<any> = useDispatch();

    useEffect(() => {
        dispatch(fetchDesign(slug));
        dispatch(fetchDesignFloorPlan(id));
    }, []);

    useEffect(() => {
        const delay = 1000; // Set the delay in milliseconds (e.g., 3000 ms = 3 seconds)

        const timeoutId = setTimeout(() => {
            dispatch(fetchSingleLocation(Design?.address));
        }, delay);

        return () => {
            clearTimeout(timeoutId); // Clear the timeout if the component unmounts before the delay is reached
        };
    }, [Design?.address]);

    console.log('DesignFloorPlan', Design?.address)
    const divStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '300px'
    }
    const divStyle2 = {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        backgroundSize: 'cover',

    }
    const spanStyle = {
        padding: '20px',
        color: '#fff'

    }

    const slideImages = DesignFloorPlan
    const slideImages2 = [
        {img: "/img/designs/Frame154.png", title: "Deluxe", description: "This is a description"},
        {img: "/img/designs/Frame154.png", title: "Imperial", description: "This is a description"},
    ];
    return (
        <div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 1.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 1.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color absolute top-0 left-0 z-50"
            ></motion.div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 1, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: ``, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color2 absolute top-0 left-0 z-50"
            ></motion.div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 0.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color3 absolute top-0 left-0 z-50"
            ></motion.div>
            <section className={`relative`}>
                <div className={`menu-color h-screen-65 w-screen`} style={{ backgroundImage: `url("/img/home/pattern.png")`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundBlendMode: 'color-dodge' }}>
                    <div className={`mx-auto h-screen-65 flex justify-center items-center w-screen`}>
                        <div className={`flex flex-col-reverse md:flex-row  mx-auto justify-center items-center h-full w-screen `}>
                            <div className={`md:w-5/12 px-3 pb-14 md:px-10 w-full`}>
                                <p className={`md:text-4xl text-2xl font-bold mt-10 my pb-4 md:pb-14 tracking-normal text-white`}>
                                    {Design?.title?.split(' ').map((word: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | null | undefined, index: React.Key | null | undefined) => (
                                        index === 0 ? (
                                            <span key={index} style={{ color: '#CA942B', marginRight:'5px' }}>{word}</span>
                                        ) : (
                                            word + ' '
                                        )
                                    ))}
                                </p>
                            </div>
                            <div className={`md:w-7/12 h-full w-full`} style={{ backgroundImage: `url(${Design.main_image})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                                {/*<img src={Design.main_image} alt="" className={`md:hidden`}/>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={`relative`}>
                <div className={`container mx-auto py-20 px-2 md:px-0`}>
                    <div className={`flex flex-col justify-center h-full mt-10`}>
                        <Parallax speed={1}>
                            <div className={`flex flex-col container mx-auto md:w-6/12 text-center`}>
                                <p className={`md:text-4xl text-2xl font-bold tracking-normal pb-10`}>
                                   Project Description
                                </p>
                                <p className={`text-lg tracking-normal pb-5`}>
                                    {Design.overview}
                                </p>
                                <p className={`text-lg tracking-normal`}>
                                    {Design.overview}
                                </p>
                            </div>
                        </Parallax>
                    </div>

                </div>
            </section>
            <section className={`relative`}>
                <div className={`container mx-auto px-2 md:px-0`}>
                    <div className={`flex flex-col justify-center h-full`}>
                        <div className={`grid grid-cols-1 md:grid-cols-2`}>
                            <Parallax speed={1}>
                                <div className={`flex flex-col container mx-auto`}>
                                    <p className={`text-2xl font-bold tracking-normal pb-10`}>
                                        Floor Plan
                                    </p>
                                    <div className={`text-lg tracking-normal pb-5`}>
                                        <Fade autoplay={true} arrows={false} indicators={true} transitionDuration={500} duration={6000}>
                                            {slideImages.map((slideImages: any, index: React.Key | null | undefined)=> (
                                                <div key={index}>
                                                    <div className={`justify-end items-end h-full h-screen-50 md:h-screen-75`} style={{ ...divStyle, 'backgroundImage': `url(${slideImages.floor_plan})`, 'backgroundPosition': `center`}}></div>
                                                </div>
                                            ))}
                                        </Fade>
                                    </div>
                                </div>
                            </Parallax>
                            <Parallax speed={1}>
                                <div className={`flex flex-col container mx-auto text-center p-4 md:p-10 border border-gray-300`}>
                                    <div className={`flex justify-between border-b border-t border-gray-300`}>
                                        <div className={`flex items-center`}>
                                            <img src="/img/designs/3d-square.png" alt="" className={`w-7`}/>
                                            <p className={`text-lg text-gray-600 font-semibold tracking-normal p-3 `}>
                                                Square footage
                                            </p>
                                        </div>
                                        <p className={`text-lg tracking-normal p-3 `}>
                                            {Design.square_foot} sq.ft
                                        </p>
                                    </div>


                                    <div className={`flex justify-between border-b border-gray-300`}>
                                        <div className={`flex items-center`}>
                                            <img src="/img/designs/bed-outline.png" alt="" className={`w-7`}/>
                                            <p className={`text-lg text-gray-600 font-semibold tracking-normal p-3 `}>
                                                Bedroom
                                            </p>
                                        </div>
                                        <p className={`text-lg tracking-normal p-3 `}>
                                            {Design.bedrooms}
                                        </p>
                                    </div>
                                    <div className={`flex justify-between border-b border-gray-300`}>
                                        <div className={`flex items-center`}>
                                            <img src="/img/designs/shower.png" alt="" className={`w-7`}/>
                                            <p className={`text-lg text-gray-600 font-semibold tracking-normal p-3 `}>
                                                Bathroom
                                            </p>
                                        </div>
                                        <p className={`text-lg tracking-normal p-3 `}>
                                            {Design.bathrooms}
                                        </p>
                                    </div>
                                    <div className={`flex justify-between border-b border-gray-300`}>
                                        <div className={`flex items-center`}>
                                            <img src="/img/designs/ph_garage-light.png" alt="" className={`w-7`}/>
                                            <p className={`text-lg text-gray-600 font-semibold tracking-normal p-3 `}>
                                                Living Room
                                            </p>
                                        </div>
                                        <p className={`text-lg tracking-normal p-3 `}>
                                            {Design.garage}
                                        </p>
                                    </div>
                                    {/*<div className={`flex justify-between border-b border-gray-300`}>*/}
                                    {/*    <div className={`flex items-center`}>*/}
                                    {/*        <img src="/img/designs/home.png" alt="" className={`w-7`}/>*/}
                                    {/*        <p className={`text-lg text-gray-600 font-semibold tracking-normal p-3 `}>*/}
                                    {/*            House type*/}
                                    {/*        </p>*/}
                                    {/*    </div>*/}
                                    {/*    <p className={`text-lg tracking-normal p-3 `}>*/}
                                    {/*        {Design.square_foot} sq.ft*/}
                                    {/*    </p>*/}
                                    {/*</div>*/}
                                </div>
                            </Parallax>
                        </div>
                    </div>

                </div>
            </section>
            <section className={`relative`}>
                <div className={`container mx-auto py-20 px-2 md:px-0`}>
                    <div className={`flex flex-col justify-center h-full `}>
                        <Parallax speed={1}>
                            <div className={`flex flex-col container mx-auto md:w-8/12 `}>
                                <p className={`md:text-4xl text-2xl font-bold tracking-normal text-center pb-10`}>
                                    Location
                                </p>
                                <p className={`text-lg text-center tracking-normal pb-5`}>
                                    {SingleLocation?.address}
                                </p>
                                <p className={`text-lg tracking-normal text-center pb-8`}>
                                    Are you interested in this design? <span className={`main-text-color`}>
                                    We will contact you
                                </span>
                                </p>
                                <InterestForm designs={Design?.title} state={SingleLocation?.title}/>
                            </div>
                        </Parallax>
                    </div>

                </div>
            </section>
            {/*<section>*/}
            {/*    <div className={`text-lg tracking-normal pb-5`}>*/}
            {/*        <Fade autoplay={true} arrows={false} indicators={true} transitionDuration={500} duration={6000}>*/}
            {/*            {slideImages.map((slideImages: any, index: React.Key | null | undefined)=> (*/}
            {/*                <div key={index}>*/}
            {/*                    <div className={`justify-end items-end h-full h-screen-50 md:h-screen-75`} style={{ ...divStyle2, 'backgroundImage': `url(${slideImages.floor_plan})` }}>*/}
            {/*                       <div className={`flex items-center w-full mb-20`}>*/}
            {/*                           <div className="line2"></div>*/}
            {/*                           <span style={spanStyle} className={`md:text-4xl text-2xl font-semibold`}>{slideImages.title}</span>*/}
            {/*                           <div className="line2"></div>*/}
            {/*                       </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            ))}*/}
            {/*        </Fade>*/}
            {/*    </div>*/}
            {/*    <div className={`grid grid-cols-1 md:grid-cols-3 items-center`}>*/}
            {/*        <p className={`md:text-2xl text-xl font-bold tracking-normal pb-10`}>*/}
            {/*            Plan Features*/}
            {/*        </p>*/}
            {/*        <div className={`md:text-xl text-lg`}>*/}
            {/*            <p className={`main-text-color font-semibold`}>Ground Floor</p>*/}
            {/*        </div>*/}
            {/*        <div className={`md:text-xl text-lg`}>*/}
            {/*            <p className={`main-text-color font-semibold`}>First Floor</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}

        </div>
    );
}

export default SingleDesign;