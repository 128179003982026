import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL;


const getProjectsPage = async () => {
    const response = await axios.get(API_URL  + 'companies/',)
    return response.data
}


const projectPageService = {
    getProjectsPage
}

export default projectPageService