import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL;


const getBlogsPage = async () => {
    const response = await axios.get(API_URL  + 'blogs/',)
    return response.data
}

const getSingleBlog = async (data:any ) => {
    const response = await axios.get(API_URL  + 'blogs/'+ data + '/',)
    return response.data
}


const homePageService = {
    getBlogsPage,
    getSingleBlog,
}

export default homePageService