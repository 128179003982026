import React, {Dispatch, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {AnimatePresence, motion, useAnimation, useIsPresent} from 'framer-motion';
import {Parallax} from "react-scroll-parallax";
import {Link, useParams} from "react-router-dom";
import {NavContext, NavContextType} from "../../util/nav_context";

import {useDispatch, useSelector} from "react-redux";
import {fetchBlog} from "../../features/blog/blogPageApiSlice";
import moment from "moment/moment";
import {ListBulletIcon} from "@heroicons/react/20/solid";
import Opener from "../../components/opener/opener";


SingleBlog.propTypes = {

};

function SingleBlog(props:any) {
    const { slug } = useParams();
    const isPresent = useIsPresent();
    const { Blog } = useSelector((state:any) => state.blogsPage)
    function truncateText(text: string, maxLength: number) {
        if (text.length <= maxLength) {
            return text;
        } else {
            // Truncate the text and add ellipses
            return text.slice(0, maxLength) + '...';
        }
    }
    const dispatch: Dispatch<any> = useDispatch();
    useEffect(() => {
        dispatch(fetchBlog(slug));
    }, []);

    // const { content } = Blog;
    //
    // const midpoint = Math.floor(content.length / 2);
    //
    // const part1 = content.slice(0, midpoint);
    // const part2 = content.slice(midpoint);
    //
    // const maxLength = 500;
    return (
        <div>
            <Opener/>
            <section className={`relative`}>
                <div className={``}>

                    <div className={`mx-auto flex-col justify-center items-center`}>
                        <div className={`flex flex-col-reverse md:flex-row mx-auto justify-center items-center h-full `}>
                            <div className={`md:w-10/12 px-5 pb-14 md:px-10`}>
                                <div className={`container mx-auto mt-10`}>
                                    <div className={`flex flex-col md:flex-row items-center justify-start md:justify-start`}>
                                        <Link to={`/`} className={` text-gray-500`}>
                                            Home
                                        </Link>
                                        <p className={` text-gray-500 mx-2`}>/</p>
                                        <Link to={`/blogs`} className={` text-gray-500`}>
                                            Blogs
                                        </Link>
                                        <p className={` text-gray-500 mx-2`}>/</p>
                                        <p className={`text-amber-600`}>
                                            {Blog?.title}
                                        </p>
                                    </div>
                                </div>
                                <p className={`md:text-5xl text-4xl font-bold mt-20 my pb-4  tracking-normal `}>
                                    {Blog?.title}
                                </p>
                                <div className={`flex flex-col md:flex-row md:gap-5 justify-start items-center`}>
                                    <p className={`tracking-normal `}>
                                        {moment(Blog?.created_at).format("dddd, MMMM Do YYYY")},
                                    </p>

                                    <p className={`tracking-normal `}>
                                        Author: <span className={`font-bold`}>{Blog?.author}</span>
                                    </p>
                                    {Blog?.link &&
                                        <>
                                            {Blog?.link !== 'None' &&
                                                <p className={`tracking-normal `}>
                                                    From: <Link to={Blog?.link}><span className={`font-bold underline main-text-color`}>{truncateText(Blog?.link,30)}</span></Link>
                                                </p>
                                            }
                                        </>}
                                </div>

                            </div>

                        </div>
                        <div className={`mx-auto px-5 container h-96 `}>
                            <div className={`h-96 md:h-105 transform transition cursor-pointer delay-100 duration-1000 ease-in-out hover:scale-105`} style={{ backgroundImage: `url(${Blog?.main_image})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={`relative mt-10 md:mt-20`}>
                {/*<div className={`absolute right-0 top-20`}>*/}
                {/*    <img src="/img/home/sec2_bg_img_2.png" alt="" className={`w-56`}/>*/}
                {/*</div>*/}
                <div className={`container mx-auto py-20 px-2 md:px-0`}>
                    <div className={`flex flex-col md:flex-row`}>
                        <div className={`md:w-4/12 px-5 pb-14 md:px-10`}>
                            <p className={`uppercase text-2xl`}>
                                "{Blog?.sub_content}"
                            </p>
                            <div className={`flex flex-col mt-3`}>
                                <p>Tags</p>
                                <p className={`flex gap-2 flex-wrap`}>
                                    {Blog?.tags?.split(',').map((tag:any, index:number) => (
                                        <span key={index} className={`text-sm bg-amber-600 rounded px-2 text-white whitespace-nowrap`}>{tag}</span>
                                    ))}
                                </p>
                            </div>

                        </div>
                        <div className={`md:w-8/12 px-5 pb-14 md:px-10`}>
                            <div className={`text-justify`} dangerouslySetInnerHTML={{ __html: Blog?.content }} />
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default SingleBlog;
