import React, {useContext} from 'react';
import { motion,  useIsPresent} from 'framer-motion';
import {Parallax} from "react-scroll-parallax";
import {NavContext, NavContextType} from "../../util/nav_context";
import Contact from "../../components/contact/contact";
import Testimonial from "../../components/testimonial/testimonial";
import Slider from "../../components/slider/slider";
import CompaniesCarousel from "../../components/companies_carousel/companies_carousel";
import {Link} from "react-router-dom";


Companies.propTypes = {

};



function Companies(props:any) {
    const isPresent = useIsPresent();
    const navContextData = useContext(NavContext) as NavContextType
    const { ProjectsPage } = navContextData

    return (
        <div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 1.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 1.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color absolute top-0 left-0 z-50"
            ></motion.div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 1, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: ``, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color2 absolute top-0 left-0 z-50"
            ></motion.div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 0.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color3 absolute top-0 left-0 z-50"
            ></motion.div>
            <section className={`relative`}>
                <div className={`menu-color h-screen-65`}>
                    <div className={`mx-auto h-screen-65 flex justify-center items-center`}>
                        <div className={`flex flex-col-reverse md:flex-row  mx-auto justify-center items-center h-full `}>
                            <div className={`md:w-5/12 px-3 pb-14 md:px-10`}>
                                <p className={`md:text-4xl font-bold mt-10 my pb-4 md:pb-14 tracking-normal text-white`}>
                                    Our Projects
                                </p>
                                <p className={`md:text-2xl pb-4 tracking-normal text-white`}>
                                    We have been actively engaged in Real Estate development across Nigeria for the last 10 years. Wherever we invest, we partner with highly skilled management teams to build leading  businesses.
                                </p>

                            </div>
                            <div className={`md:w-7/12 h-full`} style={{ backgroundImage: `url('/img/companies/img.png')`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                                <img src="/img/about/part1.png" alt="" className={`md:hidden`}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={`min-h-screen-75 relative  `}>
                <div className={`absolute`}>
                    <img src="/img/home/sec2_bg_img_1.png" alt="" className={`w-32`}/>
                </div>
                <div className={`absolute right-0 bottom-0`}>
                    <img src="/img/home/sec2_bg_img_2.png" alt="" className={`w-56`}/>
                </div>
                <div className={`container mx-auto min-h-screen-75 py-20 px-2 md:px-0`}>
                    <div className={`flex flex-col justify-center h-full`}>
                        <Parallax speed={11}>
                            <div className={`flex flex-col container mx-auto xl:w-10/12`}>
                                {ProjectsPage.map((company:any, index:any) => (
                                    <div
                                        key={index}
                                        className={`flex flex-col-reverse md:flex-row gap-4 md:gap-7 lg:gap-16 xl:gap-20 py-6 md:py-16 h-full w-full items-center ${
                                            index % 2 !== 0 ? 'md:flex-row-reverse' : '' // Switch div order for odd indices
                                        }`}
                                    >
                                        <div className="flex flex-col py-10 items-start md:w-6/12 px-2">
                                            <p className={`md:text-2xl font-bold tracking-normal pb-1 border-b border-main `}>
                                                {company.title}
                                            </p>
                                            <p className={`tracking-normal py-10`}>{company.description}</p>
                                            <Link to={company.link} target="_blank" rel="noopener noreferrer">
                                                <p className={`font-bold tracking-normal cursor-pointer pb-1 border-b border-main`}>Explore Project</p>
                                            </Link>
                                        </div>

                                        <div className="flex md:w-6/12 relative w-full h-64">
                                            <div className={`absolute ${index % 2 !== 0 ? 'right-0':'left-0'} bottom-0 `}>
                                                <Parallax speed={3}>
                                                    <div
                                                        className={`w-56 h-44 md:w-56 md:h-64 lg:h-56 lg:w-96 xl:h-64 xl:w-96 main-color`}
                                                    ></div>
                                                </Parallax>
                                            </div>
                                            <div className={`absolute left-0 bottom-0 m-2 `}>
                                                <img src={company.main_image} alt="" className={`shadow-2xl`}/>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Parallax>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Companies;
