import React from 'react';
import PropTypes from 'prop-types';

NotFound.propTypes = {

};

function NotFound(props:any) {
    return (
        <div>Not Found page</div>
    );
}

export default NotFound;