import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {RootState} from "../../app/store";
import BlogsPageService from "./blogPageApiService";




interface initState {
    BlogsPage:any,
    Blog:any,
    isLoadingBlogsPage: boolean,
    isErrorBlogsPage: boolean,
    isSuccessBlogsPage: boolean,
    messageBlogsPage: any,
}

const initialState: initState = {
    BlogsPage: [],
    Blog: {},
    isLoadingBlogsPage: false,
    isErrorBlogsPage: false,
    isSuccessBlogsPage: false,
    messageBlogsPage: "",
}


export const fetchBlogsPage = createAsyncThunk(
    'fetchBlogsPage',
    async (_, thunkAPI) => {
        try {
            return await BlogsPageService.getBlogsPage()
        } catch (error:any) {
            // if the data.error exists loop through all the error and display the error in a comma sepereated string else return the data.message
            if (error.response && error.response.data && error.response.data.error) {
                const response = error.response.data;
                const errorFields = Object.keys(response.error);
                const errorMessages = errorFields.reduce((acc:any, field) => {
                    const fieldErrors = response.error[field];
                    return [...acc, ...fieldErrors];
                }, []);
                const errorMessage = errorMessages.join('');

                console.log('Errors:', errorMessage);
                throw new Error(errorMessage);


            } else if(error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);

            } else {
                throw new Error("An error occurred");
            }
        }
    }
)

export const fetchBlog = createAsyncThunk(
    'fetchBlog',
    async (data:any) => {
        try {
            return await BlogsPageService.getSingleBlog(data)
        } catch (error:any) {
            // if the data.error exists loop through all the error and display the error in a comma sepereated string else return the data.message
            if (error.response && error.response.data && error.response.data.error) {
                const response = error.response.data;
                const errorFields = Object.keys(response.error);
                const errorMessages = errorFields.reduce((acc:any, field) => {
                    const fieldErrors = response.error[field];
                    return [...acc, ...fieldErrors];
                }, []);
                const errorMessage = errorMessages.join('');

                console.log('Errors:', errorMessage);
                throw new Error(errorMessage);

            } else if(error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);

            } else {
                throw new Error("An error occurred");
            }
        }
    }
)


export const BlogsPageSlice = createSlice({
    name: 'BlogsPageSlice',
    initialState,
    reducers: {
        BlogReset: (state) => {
            state.isLoadingBlogsPage = false
            state.isSuccessBlogsPage = false
            state.isErrorBlogsPage = false
            state.messageBlogsPage = ''
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBlogsPage.pending, (state) => {
                state.isLoadingBlogsPage = true
            })
            .addCase(fetchBlogsPage.fulfilled, (state, action) => {
                state.isLoadingBlogsPage = false
                state.isSuccessBlogsPage = true
                state.BlogsPage = action.payload
                state.messageBlogsPage = action.payload.message
            })
            .addCase(fetchBlogsPage.rejected, (state, action) => {
                state.isLoadingBlogsPage = false
                state.isErrorBlogsPage = true
                state.messageBlogsPage = action.error.message
                state.BlogsPage = null
            })
            .addCase(fetchBlog.pending, (state) => {
                state.isLoadingBlogsPage = true
            })
            .addCase(fetchBlog.fulfilled, (state, action) => {
                state.isLoadingBlogsPage = false
                state.isSuccessBlogsPage = true
                state.Blog = action.payload
                state.messageBlogsPage = action.payload.message
            })
            .addCase(fetchBlog.rejected, (state, action) => {
                state.isLoadingBlogsPage = false
                state.isErrorBlogsPage = true
                state.messageBlogsPage = action.error.message
                state.Blog = null
            })
    },
})

export const { BlogReset } = BlogsPageSlice.actions
export default BlogsPageSlice.reducer