import React, {useContext} from 'react';
import { motion,  useIsPresent} from 'framer-motion';
import {Parallax} from "react-scroll-parallax";
import {NavContext, NavContextType} from "../../util/nav_context";
import Contact from "../../components/contact/contact";
import Testimonial from "../../components/testimonial/testimonial";
import Slider from "../../components/slider/slider";
import CompaniesCarousel from "../../components/companies_carousel/companies_carousel";


Governance.propTypes = {

};



function Governance(props:any) {
    const isPresent = useIsPresent();
    const navContextData = useContext(NavContext) as NavContextType
    const { governance } = navContextData

    return (
        <div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 1.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 1.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color absolute top-0 left-0 z-50"
            ></motion.div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 1, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: ``, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color2 absolute top-0 left-0 z-50"
            ></motion.div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 0.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color3 absolute top-0 left-0 z-50"
            ></motion.div>
            <section className={`relative`}>
                <div className={`menu-color h-screen-65`} style={{ backgroundImage: `url('/img/governance/governance.png')`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                    <div className={`mx-auto h-screen-65 flex justify-center items-center`}>
                        <div className={`flex flex-col-reverse md:flex-row  mx-auto justify-center items-center h-full `}>
                            <div className={`mx-auto container text-center py-4 w-full`}>
                                <p className={`font-semibold text-4xl text-white`}>Corporate Governance</p>
                                <p className={`main-text-color text-xl`}>Meet Our Team</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={`min-h-screen-75 relative  `}>
                <div className={`absolute`}>
                    <img src="/img/home/sec2_bg_img_1.png" alt="" className={`w-32`}/>
                </div>
                <div className={`absolute right-0 bottom-0`}>
                    <img src="/img/home/sec2_bg_img_2.png" alt="" className={`w-56`}/>
                </div>
                <div className={`container mx-auto min-h-screen-75 py-20 px-2 md:px-0`}>
                    <div className={`flex flex-col justify-center h-full`}>
                        <div className={`flex flex-col`}>
                            <Parallax speed={3}>
                                <div className={`mx-auto text-lg container text-center md:w-5/12`}>
                                    <p className={`font-semibold text-2xl`}>Meet Our
                                        <span className={`main-text-color h-screen`}> Amazing Team</span>
                                    </p>
                                    <p>
                                        RHG is committed towards an open corporate governance system whereby its activities are managed and undertaken ethically, transparently, and in the interests of all concerned stakeholders. Corporate governance is operated through the Board of Directors.
                                    </p>
                                </div>
                            </Parallax>
                        </div>

                        <Parallax speed={11}>
                            <div className={`grid md:grid-cols-2  md:gap-20 lg:gap-32 container mx-auto xl:w-10/12`}>
                                {governance.map((govern:any, index:any) => (
                                    <div
                                        key={index}
                                        className={`flex flex-col h-full w-full items-center shadow-2xl bg-white `}
                                    >
                                        <div className={`main-color h-5 w-full`}>.</div>
                                        <div className="relative w-full h-96 md:h-105">
                                            <div className={`menu-color h-96 md:h-105`} style={{ backgroundImage: `url('${govern.image}')`, backgroundPosition: 'top', backgroundSize: 'cover' }}>

                                            </div>
                                        </div>
                                        <div className="flex flex-col pt-2 px-2 text-center">
                                            <p className={`tracking-normal py-10`}>{govern.bio}</p>
                                            <p className={`font-bold tracking-normal pb-1  `}>
                                                {govern.name}
                                            </p>
                                            <p className={`tracking-normal pb-1 main-text-color`}>
                                                {govern.title}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Parallax>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Governance;
