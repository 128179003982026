import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL;


const getDesignsPage = async () => {
    const response = await axios.get(API_URL  + 'designs/',)
    return response.data
}

const getSingleDesign = async (data:any ) => {
    const response = await axios.get(API_URL  + 'designs/'+ data + '/',)
    return response.data
}

const getSingleDesignFloorPlan = async (data:any ) => {
    const response = await axios.get(API_URL  + 'designs/floorplans/'+ data + '/',)
    return response.data
}

const getLocations = async () => {
    const response = await axios.get(API_URL  + 'location/')
    return response.data
}

const getSingleLocation = async (data:any ) => {
    const response = await axios.get(API_URL  + 'location/'+ data + '/',)
    return response.data
}


const postInterest = async (data:any ) => {
    try {
        const response = await axios.post(API_URL + 'interests/', data, {
            headers: {
                'Content-Type': 'application/json', // Ensure it matches the expected content type
            },
        });
        return response.data;
    } catch (error) {
        // Handle errors as needed
        throw error; // You can rethrow the error or handle it here
    }
}



const getGovernance = async () => {
    const response = await axios.get(API_URL  + 'governance/')
    return response.data
}

const homePageService = {
    getDesignsPage,
    getSingleDesign,
    getSingleDesignFloorPlan,
    getLocations,
    getSingleLocation,
    postInterest,
    getGovernance
}

export default homePageService