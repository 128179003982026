import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL;


const getHomePage = async () => {
    const response = await axios.get(API_URL  + 'home/',)
    return response.data
}

const getTestimonials = async () => {
    const response = await axios.get(API_URL  + 'testimonials/',)
    return response.data
}

const postSubscription = async (data: any) => {
    try {
        const response = await axios.post(API_URL + 'subscriptions/', data, {
            headers: {
                'Content-Type': 'application/json', // Ensure it matches the expected content type
            },
        });
        return response.data;
    } catch (error) {
        // Handle errors as needed
        throw error; // You can rethrow the error or handle it here
    }
};
const postContact = async (data: any) => {
    try {
        const response = await axios.post(API_URL + 'contact/', data, {
            headers: {
                'Content-Type': 'application/json', // Ensure it matches the expected content type
            },
        });
        return response.data;
    } catch (error) {
        // Handle errors as needed
        throw error; // You can rethrow the error or handle it here
    }
};

const homePageService = {
    getHomePage,
    getTestimonials,
    postSubscription,
    postContact
}

export default homePageService