import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {RootState} from "../../app/store";
import homePageService from "./homePageApiService";
import DesignsPageService from "../design/designPageApiService";




interface initState {
    homePage:any,
    testimonials:any,
    subscriber:any,
    contact:any,
    isLoadingHomePage: boolean,
    isLoadingTestimonial: boolean,
    isErrorHomePage: boolean,
    isSuccessHomePage: boolean,
    isErrorContact: boolean,
    isSuccessContact: boolean,
    messageHomePage: any,
}

const initialState: initState = {
    homePage: [],
    testimonials: [],
    subscriber: {},
    contact: {},
    isLoadingHomePage: false,
    isLoadingTestimonial: false,
    isErrorHomePage: false,
    isErrorContact: false,
    isSuccessHomePage: false,
    isSuccessContact: false,
    messageHomePage: "",
}


export const fetchHomePage = createAsyncThunk(
    'fetchHomePage',
    async (_, thunkAPI) => {
        try {
            return await homePageService.getHomePage()
        } catch (error:any) {
            // if the data.error exists loop through all the error and display the error in a comma sepereated string else return the data.message
            if (error.response && error.response.data && error.response.data.error) {
                const response = error.response.data;
                const errorFields = Object.keys(response.error);
                const errorMessages = errorFields.reduce((acc:any, field) => {
                    const fieldErrors = response.error[field];
                    return [...acc, ...fieldErrors];
                }, []);
                const errorMessage = errorMessages.join('');

                console.log('Errors:', errorMessage);
                throw new Error(errorMessage);


            } else if(error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);

            } else {
                throw new Error("An error occurred");
            }
        }
    }
)
export const fetchTestimonials = createAsyncThunk(
    'fetchTestimonials',
    async (_, thunkAPI) => {
        try {
            return await homePageService.getTestimonials()
        } catch (error:any) {
            // if the data.error exists loop through all the error and display the error in a comma sepereated string else return the data.message
            if (error.response && error.response.data && error.response.data.error) {
                const response = error.response.data;
                const errorFields = Object.keys(response.error);
                const errorMessages = errorFields.reduce((acc:any, field) => {
                    const fieldErrors = response.error[field];
                    return [...acc, ...fieldErrors];
                }, []);
                const errorMessage = errorMessages.join('');

                console.log('Errors:', errorMessage);
                throw new Error(errorMessage);

            } else if(error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);

            } else {
                throw new Error("An error occurred");
            }
        }
    }
)

export const Subscribe = createAsyncThunk(
    'Subscribe',
    async (data:any) => {
        try {
            return await homePageService.postSubscription(data)
        } catch (error:any) {
            // if the data.error exists loop through all the error and display the error in a comma sepereated string else return the data.message
            if (error.response && error.response.data && error.response.data.error) {
                const response = error.response.data;
                const errorFields = Object.keys(response.error);
                const errorMessages = errorFields.reduce((acc:any, field) => {
                    const fieldErrors = response.error[field];
                    return [...acc, ...fieldErrors];
                }, []);
                const errorMessage = errorMessages.join('');

                console.log('Errors:', errorMessage);
                throw new Error(errorMessage);

            } else if(error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);

            } else {
                throw new Error("An error occurred");
            }
        }
    }
)
export const PostContact = createAsyncThunk(
    'PostContact',
    async (data:any) => {
        try {
            return await homePageService.postContact(data)
        } catch (error:any) {
            // if the data.error exists loop through all the error and display the error in a comma sepereated string else return the data.message
            if (error.response && error.response.data && error.response.data.error) {
                const response = error.response.data;
                const errorFields = Object.keys(response.error);
                const errorMessages = errorFields.reduce((acc:any, field) => {
                    const fieldErrors = response.error[field];
                    return [...acc, ...fieldErrors];
                }, []);
                const errorMessage = errorMessages.join('');

                console.log('Errors:', errorMessage);
                throw new Error(errorMessage);

            } else if(error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);

            } else {
                throw new Error("An error occurred");
            }
        }
    }
)


export const homePageSlice = createSlice({
    name: 'homePageSlice',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoadingTestimonial = false
            state.isLoadingHomePage = false
            state.isSuccessHomePage = false
            state.isErrorHomePage = false
            state.isSuccessContact = false
            state.isErrorContact = false
            state.messageHomePage = ''
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchHomePage.pending, (state) => {
                state.isLoadingHomePage = true
            })
            .addCase(fetchHomePage.fulfilled, (state, action) => {
                state.isLoadingHomePage = false
                state.isSuccessHomePage = true
                state.homePage = action.payload
                state.messageHomePage = action.payload.message
            })
            .addCase(fetchHomePage.rejected, (state, action) => {
                state.isLoadingHomePage = false
                state.isErrorHomePage = true
                state.messageHomePage = action.error.message
                state.homePage = null
            })
            .addCase(fetchTestimonials.pending, (state) => {
                state.isLoadingTestimonial = true
            })
            .addCase(fetchTestimonials.fulfilled, (state, action) => {
                state.isLoadingTestimonial = false
                state.isSuccessHomePage = true
                state.testimonials = action.payload
                state.messageHomePage = action.payload.message
            })
            .addCase(fetchTestimonials.rejected, (state, action) => {
                state.isLoadingTestimonial = false
                state.isErrorHomePage = true
                state.messageHomePage = action.error.message
                state.testimonials = null
            })
            .addCase(Subscribe.pending, (state) => {
                state.isLoadingTestimonial = true
            })
            .addCase(Subscribe.fulfilled, (state, action) => {
                state.isLoadingTestimonial = false
                state.isSuccessHomePage = true
                state.subscriber = action.payload
                state.messageHomePage = action.payload.message
            })
            .addCase(Subscribe.rejected, (state, action) => {
                state.isLoadingTestimonial = false
                state.isErrorHomePage = true
                state.messageHomePage = action.error.message
                state.testimonials = null
            })
            .addCase(PostContact.pending, (state) => {
                state.isLoadingTestimonial = true
            })
            .addCase(PostContact.fulfilled, (state, action) => {
                state.isLoadingTestimonial = false
                state.isSuccessContact = true
                state.contact = action.payload
                state.messageHomePage = action.payload.message
            })
            .addCase(PostContact.rejected, (state, action) => {
                state.isLoadingTestimonial = false
                state.isErrorContact = true
                state.messageHomePage = action.error.message
                state.testimonials = null
            })
    },
})

export const { reset } = homePageSlice.actions
export default homePageSlice.reducer