import React, {Dispatch, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {AnimatePresence, motion, useAnimation, useIsPresent} from 'framer-motion';
import {Parallax} from "react-scroll-parallax";
import {Link, useParams} from "react-router-dom";
import {NavContext, NavContextType} from "../../util/nav_context";
import {PlusIcon} from "@heroicons/react/24/solid";
import Slider from "../../components/slider/slider";
import {useDispatch, useSelector} from "react-redux";
import {fetchDesign, fetchDesignFloorPlan} from "../../features/design/designPageApiSlice";
import {fetchBlog, fetchBlogsPage} from "../../features/blog/blogPageApiSlice";
import moment from "moment";
import {TruncatedHtml} from "../../components/truncate/truncate";


Blog.propTypes = {

};

function Blog(props:any) {
    const isPresent = useIsPresent();
    const dispatch: Dispatch<any> = useDispatch()
    const { BlogsPage, Blog } = useSelector((state:any) => state.blogsPage)

    useEffect(() => {
        dispatch(fetchBlogsPage())
    }, [])
    function truncateText(text: string, maxLength: number) {
        if (text.length <= maxLength) {
            return text;
        } else {
            // Truncate the text and add ellipses
            return text.slice(0, maxLength) + '...';
        }
    }


    return (
        <div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 1.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 1.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color absolute top-0 left-0 z-50"
            ></motion.div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 1, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: ``, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color2 absolute top-0 left-0 z-50"
            ></motion.div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 0.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color3 absolute top-0 left-0 z-50"
            ></motion.div>
            <section className={`relative`}>
                <div className={` h-screen-65`}>
                    <div className={`mx-auto h-screen-65 flex justify-center items-center`}>
                        <div className={`flex flex-col-reverse md:flex-row mx-auto justify-center items-center h-full `}>
                            <div className={`md:w-6/12 px-5 pb-14 md:px-10`}>
                                <p className={`md:text-4xl font-bold mt-10 my pb-4 md:pb-14 tracking-normal `}>
                                    Stayed Informed
                                </p>
                                <p className={`md:text-xl pb-4 tracking-normal `}>
                                    Exciting Information coming soon
                                </p>
                            </div>
                            <div className={`md:w-6/12 `} >
                                <img src="/img/blog/blog1.png" alt="" className={`px-5`}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={`relative`}>
                <div className={`absolute right-0 top-20`}>
                    <img src="/img/home/sec2_bg_img_2.png" alt="" className={`w-56`}/>
                </div>
                <div className={`container mx-auto py-20 px-2 md:px-0`}>
                    <div className={`grid md:grid-cols-3 h-full mt-10`}>
                        {BlogsPage.map((blog:any, index:number) => (
                            <div key={index} className={`flex flex-col`}>
                                <Link to={`/blog/${blog.slug}`}>
                                <div className={`h-96 transform transition cursor-pointer delay-100 duration-1000 ease-in-out hover:grayscale hover:shadow-2xl`}
                                     style={{
                                         backgroundImage: `url(${blog.main_image})`,
                                         backgroundPosition: 'center',
                                         backgroundSize: 'cover' }}
                                >

                                </div>
                                <p className={`py-2 font-semibold text-xl`}>
                                    {blog.title}
                                </p>
                                </Link>
                                <p className={`pb-5`}>
                                    {TruncatedHtml(blog.content, 150)}
                                </p>
                                <div className={`flex justify-between pb-5 text-sm text-gray-500`}>
                                    <p>posted on: {moment(blog.created_at).format("dddd, MMMM Do YYYY")}
                                    </p>
                                    {/*<p>posted by: segun</p>*/}
                                </div>
                                <Link to={`/blog/${blog.slug}`}>
                                    <p className={`font-bold tracking-normal w-48 pb-1 self-start main-color py-2 px-12 my-3`}>Read more</p>
                                </Link>
                            </div>
                        ))}

                    </div>
                </div>
            </section>

        </div>
    );
}

export default Blog;
