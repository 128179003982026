import React, {Dispatch, useContext, useEffect, useRef, useState} from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {Subscribe} from "../../features/home_page/homePageApiSlice";
import {NavContext, NavContextType} from "../../util/nav_context";
import {PostDesignInterest} from "../../features/design/designPageApiSlice";

interface SearchBarProps {
  designs: any;
  state: any;
}



const InterestForm: React.FC<SearchBarProps> = ({ designs, state }) => {
  let input = useRef("");
  const dispatch: Dispatch<any> = useDispatch();
  const [formData, setFormData] = useState({
    email: '',
    name: '',
  })

  const navContextData = useContext(NavContext) as NavContextType
  const { Design, DesignFloorPlan, SingleLocation } = navContextData
  const onChange = (e:any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }
  const handleInterestSubmit = (e:any) => {
    e.preventDefault();
    const data = {
        name: formData.name,
        email: formData.email,
        design: designs,
        state: state,
    }
    const jsonData = JSON.stringify(data);
    dispatch(PostDesignInterest(jsonData));
    console.log(jsonData);
  }


  return (
    <>

      <form onSubmit={handleInterestSubmit} className="space-y-8">
          <div className="flex flex-col md:gap-5 gap-2 md:flex-row w-full">
            <div className={`w-full justify-start items-start`}>
              <label htmlFor="name" className="block mb-2 text-sm font-medium ">Full name</label>
              <input type="text" id="name" name="name"
                     onChange={onChange}
                     className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500" placeholder="John Doe" required/>
            </div>
            <div  className={`w-full`}>
              <label htmlFor="email" className="block mb-2 text-sm font-medium">Your email address</label>
              <input type="email" id="email" name="email"
                     onChange={onChange}
                     className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 " placeholder="john@flowbite.com" required/>
            </div>
          </div>
          <div className={`text-center`}>
            <button type="submit" className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg main-color sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 ">Send</button>
          </div>
      </form>
    </>
  );
};

export default InterestForm;
