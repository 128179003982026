
export function truncateText(content: string, maxWords: number) {
    const words = content.split(' ');
    if (words.length > maxWords) {
        const truncatedWords = words.slice(0, maxWords);
        return truncatedWords.join(' ') + '...';
    }
    return content;
}

export function TruncatedHtml(content: string, maxWords: number) {
    if (content.length <= maxWords) {
        return <div dangerouslySetInnerHTML={{ __html: content }} />;
    }

    const truncatedContent = content.substring(0, maxWords) + '...';

    return <div className={``} dangerouslySetInnerHTML={{ __html: truncatedContent }} />;
}
