import React, {useContext, useEffect, useRef, useState} from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import {LoadingManager, Texture, TextureLoader} from 'three';
import * as THREE from 'three';

// ASSETS
import EarthDayMap from '../../assets/textures/8k_earth_daymap.jpg';
import EarthNormalMap from '../../assets/textures/8k_earth_normal_map.jpg';
import EarthSpecularMap from '../../assets/textures/8k_earth_specular_map.jpg';
import EarthCloudsMap from '../../assets/textures/8k_earth_clouds.jpg';
import {NavContext, NavContextType} from "../../util/nav_context";

interface Location {
    latitude: number;
    longitude: number;
    title: string;
    state: string;
    type: string;
    img: string;
    color?: string;
}

interface EarthProps {
    showPopup: boolean;
    selectedLocation: Location | null;
    onLocationClick: (location: Location) => void;
    onClosePopup: () => void;
}

function useTextureLoader(url: string): Texture | null {
    const [texture, setTexture] = useState<Texture | null>(null);

    useEffect(() => {
        const loader = new TextureLoader(new LoadingManager());
        loader.load(url, (loadedTexture) => {
            setTexture(loadedTexture);
        });
    }, [url]);

    return texture;
}

const Earth: React.FC<EarthProps> = ({
                                         showPopup,
                                         selectedLocation,
                                         onLocationClick,
                                         onClosePopup,
                                     }) => {
    const [texturesLoaded, setTexturesLoaded] = useState(false);
    const navContextData = useContext(NavContext) as NavContextType
    const { Locations } = navContextData
    const colorMap: Texture | null = useTextureLoader(EarthDayMap);
    const normalMap: Texture | null = useTextureLoader(EarthNormalMap);
    const specularMap: Texture | null = useTextureLoader(EarthSpecularMap);
    const cloudsMap: Texture | null = useTextureLoader(EarthCloudsMap);

    const earthRef = useRef<THREE.Mesh | null>(null);
    const cloudsRef = useRef<THREE.Mesh | null>(null);

    useEffect(() => {
        // Check if all textures are loaded
        if (colorMap && normalMap && specularMap && cloudsMap) {
            setTexturesLoaded(true);
        }
    }, [colorMap, normalMap, specularMap, cloudsMap]);

    useFrame(({ clock }) => {
        const elapsedTime = clock.getElapsedTime();

        if (earthRef.current) {
            earthRef.current.rotation.y = elapsedTime / 50;
        }

        if (cloudsRef.current) {
            cloudsRef.current.rotation.y = elapsedTime / 45;
        }
    });

    // Locations data
    const locations: Location[] = Locations;
    if (!texturesLoaded) {
        // Textures are not loaded yet, you can render a loading indicator here
        return <>Loading...</>;
    }
    return (
        <>
                {/* Camera Setup */}
                <perspectiveCamera
                    fov={75}
                    aspect={window.innerWidth / window.innerHeight}
                    near={0.1}
                    far={1000}
                    position={[0, 0, 5]}
                />

                {/* Ambient Light */}
                <ambientLight intensity={4} />

                {/* Point Light (Directional Light) */}
                <pointLight color="#fff" position={[5, 0, 5]} intensity={2.2} castShadow={false} />

                <mesh ref={cloudsRef}>
                    <sphereGeometry args={[3.035, 64, 64]} />
                    <meshPhongMaterial
                        map={cloudsMap}
                        opacity={0.4}
                        depthWrite={true}
                        transparent={true}
                        side={THREE.DoubleSide}
                    />
                </mesh>
                <mesh ref={earthRef}>
                    <sphereGeometry args={[3.03, 64, 64]} />
                    <meshPhongMaterial map={colorMap}  specular={0xff0000} specularMap={specularMap} />
                    <meshStandardMaterial map={colorMap} normalMap={normalMap} metalness={0.1} roughness={0.5} />

                    {/* Add locations as dots */}
                    {locations.map((location, index) => {
                        const { latitude, longitude,color } = location;

                        // Convert latitude and longitude to radians
                        const phi = (latitude - 90) * (Math.PI / 180);
                        const theta = (90 + longitude) * (Math.PI / -90);

                        // Calculate the position on the surface of the Earth sphere
                        const radius = 3.1; // Radius of the Earth sphere
                        const x = radius * Math.sin(phi) * Math.cos(theta);
                        const y = radius * Math.cos(phi);
                        const z = radius * Math.sin(phi) * Math.sin(theta);


                        return (
                            <mesh
                                key={index}
                                position={[x, y, z]}
                                onClick={() => onLocationClick(location)} // Call the onLocationClick function
                            >
                                <sphereGeometry args={[0.04, 32, 32]} />
                                <meshBasicMaterial color={color} />
                            </mesh>
                        );
                    })}
                </mesh>
                <OrbitControls
                    enableZoom={false}
                    enablePan={true}
                    enableRotate={true}
                    zoomSpeed={0.4}
                    panSpeed={0.6}
                    rotateSpeed={0.5}
                />

        </>
    );
};

export default Earth;
