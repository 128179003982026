import React, { useState, useRef } from "react";
import Carousel from "react-multi-carousel";
import "./WithScrollbar.css";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

interface CarouselRef {
    transform: number;
    totalItems: number;
    slidesToShow: number;
    isAnimationAllowed: boolean;
    currentSlide: number;
    // Add other properties you need here
}

const WithScrollbar = () => {
    const [additionalTransform, setAdditionalTransform] = useState(0);
    // const carouselRef = useRef<CarouselState | null>(null);
    const carouselRef = useRef<CarouselRef | null>(null);

    const CustomSlider = ({ carouselState }: { carouselState: CarouselRef }) => {
        let value = 0;
        let carouselItemWidth = 0;
        if (carouselRef.current) {
            carouselItemWidth = carouselRef.current.transform;
            const maxTranslateX = Math.round(
                carouselItemWidth *
                (carouselRef.current.totalItems - carouselRef.current.slidesToShow) +
                150
            );
            value = maxTranslateX / 100;
        }

        const handleSliderChange = (e: any) => {
            if (carouselRef.current && carouselRef.current.isAnimationAllowed) {
                carouselRef.current.isAnimationAllowed = false;
            }
            const nextTransform = e.target.value * (value as number);

            const nextSlide = Math.round(nextTransform / carouselItemWidth);

            if (e.target.value === "0" && additionalTransform === 150) {
                if (carouselRef.current) {
                    carouselRef.current.isAnimationAllowed = true;
                }
                setAdditionalTransform(0);
            }

            if (carouselRef.current) {
                // You might need to adjust the properties below according to the Carousel component's state structure.
                carouselRef.current.transform = -nextTransform;
                carouselRef.current.currentSlide = nextSlide;
            }
        };

        return (
            <div className="custom-slider">
                <input
                    type="range"
                    value={Math.round(Math.abs(carouselState.transform) / value)}
                    defaultValue={0}
                    max={
                        (carouselItemWidth *
                            (carouselState.totalItems - carouselState.slidesToShow) +
                            (additionalTransform === 150 ? 0 : 150)) /
                        value
                    }
                    onChange={handleSliderChange}
                    className="custom-slider__input"
                />
            </div>
        );
    };

    return (
        <Carousel
            ssr={false}
            ref={(el) => {
                // Use type assertion to ensure compatibility
                carouselRef.current = el as unknown as CarouselRef;
            }}
            partialVisbile={false}
            customButtonGroup={<CustomSlider carouselState={carouselRef.current!} />}
            itemClass="slider-image-item"
            responsive={responsive}
            containerClass="carousel-container-with-scrollbar"
            additionalTransfrom={-additionalTransform}
            beforeChange={(nextSlide) => {
                if (nextSlide !== 0 && additionalTransform !== 150) {
                    setAdditionalTransform(150);
                }
                if (nextSlide === 0 && additionalTransform === 150) {
                    setAdditionalTransform(0);
                }
            }}
        >
            <div className="image-container increase-size">
                <div className="image-container-text">
                    <p>1</p>
                </div>
                <img
                    draggable={false}
                    style={{ width: "100%", cursor: "pointer" }}
                    src="https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
                />
            </div>
            <div className="increase-size">
                <div className="image-container-text">
                    <p>2</p>
                </div>
                <img
                    draggable={false}
                    style={{ width: "100%", cursor: "pointer" }}
                    src="https://images.unsplash.com/photo-1549396535-c11d5c55b9df?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                />
            </div>

            <div className="image-container increase-size">
                <div className="image-container-text">
                    <p>3</p>
                </div>
                <img
                    draggable={false}
                    style={{ width: "100%", cursor: "pointer" }}
                    src="https://images.unsplash.com/photo-1550133730-695473e544be?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
                />
            </div>

            <div className="image-container increase-size">
                <div className="image-container-text">
                    <p>4</p>
                </div>
                <img
                    draggable={false}
                    style={{ width: "100%", cursor: "pointer" }}
                    src="https://images.unsplash.com/photo-1550167164-1b67c2be3973?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
                />
            </div>

            <div className="image-container increase-size">
                <div className="image-container-text">
                    <p>5</p>
                </div>
                <img
                    draggable={false}
                    style={{ width: "100%", cursor: "pointer" }}
                    src="https://images.unsplash.com/photo-1550353175-a3611868086b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
                />
            </div>
            <div className="image-container increase-size">
                <div className="image-container-text">
                    <p>6</p>
                </div>
                <img
                    draggable={false}
                    style={{ width: "100%", cursor: "pointer" }}
                    src="https://images.unsplash.com/flagged/photo-1556091766-9b818bc73fad?ixlib=rb-1.2.1&auto=format&fit=crop&w=1504&q=80"
                />
            </div>
        </Carousel>
    );
};

export default WithScrollbar;
