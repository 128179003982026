import React, {useContext, useState} from 'react';
import { Select, Option } from "@material-tailwind/react";
import { Input } from "@material-tailwind/react";
import {NavContext, NavContextType} from "../../util/nav_context";
interface Design {
    id: number;
    title: string;
    description: string;
    features: string[];
    bedrooms: number;
    bathrooms: number;
    overview: string;
    main_image: string;
    garage: number;
    square_foot: number;
    slug: string;
}

interface DesignFilterProps {
    designs: Design[];
    onFilter: (filteredDesigns: Design[]) => void;
}

const DesignFilter: React.FC<DesignFilterProps> = ({ designs, onFilter }) => {
    const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
    const [selectedBedrooms, setSelectedBedrooms] = useState<string>('');
    const [selectedBathrooms, setSelectedBathrooms] = useState<string>('');
    const navContextData = useContext(NavContext) as NavContextType
    const { Locations } = navContextData
    console.log(Locations)
    const handleFilter = () => {
        // Filter designs based on selected criteria
        const filteredDesigns = designs.filter((design) => {
            if (
                (selectedFeatures.length === 0 ||
                    selectedFeatures.every((feature) => design.features.includes(feature))) &&
                (selectedBedrooms === '' || design.bedrooms === parseInt(selectedBedrooms)) &&
                (selectedBathrooms === '' || design.bathrooms === parseInt(selectedBathrooms))
            ) {
                return true;
            }
            return false;
        });

        // Callback to parent component with filtered designs
        onFilter(filteredDesigns);
    };

    return (
        <>
            <div>
                <div className={'grid grid-cols-3 gap-5 md:gap-10'}>
                    <div>
                        <label>Offerings:</label>
                        <div className="relative">
                            <select
                                className="block appearance-none w-full bg-white border-b border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                value={selectedBathrooms}
                                onChange={(e) => setSelectedBathrooms(e.target.value)}
                            >
                                <option value={'service-lot'}>Service Lot</option>
                                <option value={'Carcass'}>Carcass</option>
                                <option value={'Finished'}>Finished Products</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg
                                    className="fill-current h-6 w-6 main-text-color"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div>
                        <label>Features:</label>
                        <div className="relative">
                            <select
                                className="block appearance-none w-full bg-white border-b border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                value={selectedFeatures}
                                onChange={(e) => setSelectedBedrooms(e.target.value)}
                            >
                                {Locations.map((location:any, index:any) => (
                                    <option key={index} value={location.state}>{location.state}</option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg
                                    className="fill-current h-6 w-6 main-text-color"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div>
                        <label>Bedrooms:</label>
                        <div className="relative">
                            <select
                                className="block appearance-none w-full bg-white border-b border-gray-200 text-gray-700 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                value={selectedBedrooms}
                                onChange={(e) => setSelectedBedrooms(e.target.value)}
                            >
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg
                                    className="fill-current h-6 w-6 main-text-color"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end mt-3">
                    <button onClick={handleFilter} className={`main-color py-2 px-12`}>Apply Filters</button>
                </div>
            </div>
        </>

    );
};

export default DesignFilter;
