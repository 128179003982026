import React, {Dispatch, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";
import {PostContact} from "../../features/home_page/homePageApiSlice";

const Contact = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    })
    const onChange = (e:any) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }
    const handleContactSubmit = (e:any) => {
        e.preventDefault();
        const data = {
            name: formData.name,
            email: formData.email,
            message: formData.message,
        }
        const jsonData = JSON.stringify(data);
        dispatch(PostContact(jsonData));
        console.log(jsonData);
    }
    return (
        <div>
            <div className={`menu-color `}>
                <div className={` flex `}>
                    <div className={`flex flex-col-reverse md:flex-row  h-full `}>
                        <div className={`md:w-4/12 lg:w-4/12 h-full`} style={{ backgroundImage: `url('/img/contact/img.png')`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                            <img src="/img/contact/img.png" alt="" className={``}/>
                        </div>
                        <div className={`md:w-8/12 lg:w-8/12 px-3 md:px-32`}>
                            <p className={`md:text-4xl font-bold mt-10 my pb-4 tracking-normal main-text-color cormorant-font`}>
                                Get in touch with us
                            </p>
                                <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                                  <form onSubmit={handleContactSubmit} className="space-y-8">
                                        <div>
                                            <label htmlFor="name" className="block mb-2 text-sm font-medium text-white">Name</label>
                                            <input type="text" id="name" name="name" onChange={onChange} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500" placeholder="Full name" required/>
                                        </div>
                                        <div>
                                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-white">Your email</label>
                                            <input type="email" id="email" name="email" onChange={onChange} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 " placeholder="name@example.com" required/>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="message" className="block mb-2 text-sm font-medium text-white">Your message</label>
                                            <textarea id="message" name="message" onChange={onChange} rows={6} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 " placeholder="Leave a message..."></textarea>
                                        </div>
                                        <button type="submit" className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg main-color sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 ">Send message</button>
                                    </form>
                                </div>
                            {/*<Link to="/about">*/}
                            {/*    <button className={`main-color text-black text-md font-semibold px-10 md:px-20 py-2 rounded`}>*/}
                            {/*        About us*/}
                            {/*    </button>*/}
                            {/*</Link>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Contact.propTypes = {

};

export default Contact;