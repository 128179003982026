import React, {useContext} from 'react';
import { motion,  useIsPresent} from 'framer-motion';
import {Parallax} from "react-scroll-parallax";
import {NavContext, NavContextType} from "../../util/nav_context";
import Contact from "../../components/contact/contact";


ContactPage.propTypes = {

};



function ContactPage(props:any) {
    const isPresent = useIsPresent();


    return (
        <div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 1.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 1.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color absolute top-0 left-0 z-50"
            ></motion.div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 1, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: ``, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color2 absolute top-0 left-0 z-50"
            ></motion.div>
            <motion.div
                initial={{ scaleX: 1 }}
                animate={{ scaleX:0, transition: { duration: 0.5, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                transition={{ duration: 1 }}
                className="h-screen w-screen menu-color3 absolute top-0 left-0 z-50"
            ></motion.div>
            <section className={`relative`}>
                <div className={`menu-color h-screen-65`}>
                    <div className={` h-screen-65 flex justify-between items-center`}>
                        <div className={`flex flex-col-reverse md:flex-row  justify-center items-center h-full `}>
                            <div className={`md:w-5/12 px-3 pb-14 md:px-10`}>
                                <p className={`md:text-4xl font-bold mt-10 my pb-4 md:pb-14 tracking-normal text-white`}>
                                    Contact Us
                                </p>
                                <p className={`md:text-2xl w-full pb-4 tracking-normal text-white`}>
                                    We want to hear from you, our support staff will respond to your enquiries as soon as possible
                                </p>
                                {/*<Link to="/about">*/}
                                {/*    <button className={`main-color text-black text-md font-semibold px-10 md:px-20 py-2 rounded`}>*/}
                                {/*        About us*/}
                                {/*    </button>*/}
                                {/*</Link>*/}
                            </div>
                            <div className={`md:w-7/12 h-full w-full self-end`} style={{ backgroundImage: `url('/img/contact/img2.png')`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={`min-h-screen-75 relative  `}>
                <div className={`absolute`}>
                    <img src="/img/home/sec2_bg_img_1.png" alt="" className={`w-32`}/>
                </div>
                <div className={`absolute right-0 bottom-0`}>
                    <img src="/img/home/sec2_bg_img_2.png" alt="" className={`w-56`}/>
                </div>
                <div className={`container mx-auto min-h-screen-75 py-20 px-2 md:px-0`}>
                    <div className={`flex flex-col justify-center h-full mt-10`}>
                        <div className={`flex flex-col`}>
                            <Parallax speed={3}>
                                <div className={`mx-auto text-xl container text-center font-semibold py-4 md:w-4/12`}>
                                    <p>
                                        visit us at the following addresses
                                    </p>
                                </div>
                            </Parallax>
                        </div>

                        <Parallax speed={11}>
                            <div className={`flex flex-col container mx-auto md:w-10/12 mt-10`}>
                                <div className="flex flex-col-reverse md:flex-row center">
                                    <div className="flex flex-col py-10 justify-center md:w-6/12 px-2 md:px-20">
                                        <p className={`md:text-2xl font-bold pb-8 tracking-normal`}>
                                            Our Head Office
                                        </p>
                                        <p className={` tracking-normal `}>
                                            1 Danjuma drive off Peter Odili rd, Port Harcourt
                                        </p>
                                    </div>
                                    <div className="flex flex-col py-10 justify-center md:w-6/12 px-2 md:px-20">
                                        <p className={`md:text-2xl font-bold pb-8 tracking-normal`}>
                                            Our UK Office
                                        </p>
                                        <p className={` tracking-normal `}>
                                            U.K Office
                                            Rainbow Heritage Project Services Ltd
                                            Biz Enterprise Centre
                                            Raunds
                                            Wellingborough
                                            NN9 6GR
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col-reverse md:flex-row center">
                                    <div className="flex flex-col py-10 justify-center md:w-6/12 px-2 md:px-20">
                                        <p className={`md:text-2xl font-bold pb-8 tracking-normal`}>
                                            Open Office Hours
                                        </p>
                                        <p className={` tracking-normal `}>
                                            Monday - Friday: 9am – 5pm Saturday: Call
                                        </p>
                                    </div>
                                    <div className="flex flex-col py-10 justify-center md:w-6/12 px-2 md:px-20">
                                        <p className={`md:text-2xl font-bold pb-8 tracking-normal`}>
                                            Get in Touch
                                        </p>
                                        <p className={` tracking-normal `}>
                                            contact@rainbowheritage.com.ng,  +234 091 5649 4179, +234 091 5649 4171, +457983346211, +447932028701
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Parallax>
                    </div>

                </div>
            </section>
            <section className={`mb-20 container mx-auto px-3`}>
                <Contact/>
            </section>
        </div>
    );
}

export default ContactPage;